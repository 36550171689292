/******************************************************************************
 * Common / Molecules / Pagination
 *****************************************************************************/

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-pagination {
  --pagination--Font: var(--cn-global--Font-label--md-light);
  grid-column: -1 / 1;
  margin-block-start: pxToRem(29px);
  font: var(--pagination--Font);

  .ariator {
    display: none;

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      display: flex;
    }
  }

  .slash-separator {
    display: block;
    // margin-inline: pxToRem(10px);

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      display: none;
    }
  }

  .previous {
    svg {
      transform: rotate(180deg);
    }
  }

  .next,
  .previous {
    &.hidden {
      visibility: hidden;
    }
  }

  .step-links .cn-c-button--no-frame {
    --cn-c-button--PaddingBlock: 0;
    font: var(--pagination--Font);

    svg {
      block-size: pxToRem(15px);
    }
  }

  .current {
    display: flex;
    align-items: center;
    gap: pxToRem(10px);

    .number {
      font-weight: var(--cn-global--FontWeight-semibold);
    }

    > :not(.number):not(.total-count):not(.slash-separator):not(.total-count-mobile) {
      display: none;

      @media screen and (min-width: map.get($grid-breakpoints, lg)) {
        display: block;
      }
    }

    > .total-count-mobile {
      pointer-events: none;

      @media screen and (min-width: map.get($grid-breakpoints, lg)) {
        display: none;
      }
    }
  }

  .step-links {
    display: flex;
    align-items: center;
    gap: pxToRem(29px);
  }

  .total-count {
    pointer-events: none;

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      pointer-events: auto;
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, lg)) {
    margin-block-start: pxToRem(106px);
  }
}
