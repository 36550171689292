/******************************************************************************
 * Common / Atoms / Button / Trash
 *****************************************************************************/

@use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-button--icon-only.cn-c-button--trash {
  --cn-c-button--BackgroundColor: transparent;
  --cn-c-button--Color: var(--cn-c-page--Color);
  --cn-c-button--hover--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);
  --cn-c-button--icon-only--Dimension: #{pxToRem(40px)};
  --cn-c-button--PaddingInline: 0.3125rem;
  gap: unset;
  border: 1px solid;
  // height: 2.5rem;
  // width: 2.5rem;
}


/**
 * Component
 */

// .cn-c-button--trash {
//   // pointer-events: none;
//   // touch-action: none;

//   > svg {
//     block-size: pxToRem(12px);
//   }

//   &:hover {
//     border-width: 2px;
//   }

//   @media all and (min-width: map.get($grid-breakpoints, lg)) {
//     --cn-c-button--icon-only--Dimension: #{pxToRem(85px)};

//     > svg {
//       block-size: pxToRem(15px);
//     }
//   }
// }
