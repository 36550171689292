/******************************************************************************
 * Common / Atoms / Loader
 *****************************************************************************/

.cn-c-loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid var(--cn-c-page--Color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
