/** ***************************************************************************
 * Common / Organisms / Profile / Edit
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-profile-overlay.cn-m-edit {
  padding-block-end: 2.25rem;

  .fields {
    display: flex;
    flex-wrap: wrap;
    row-gap: pxToRem(8px);
    column-gap: pxToRem(12px);
  }

  .cn-c-field {
    margin-block: unset;

    &.newsletter {
      align-self: center;
    }
  }

  .cn-c-field:nth-child(3), .action-buttons, .password-criteria {
    width: 100%;
  }

  .cn-c-field:nth-child(1), .cn-c-field:nth-child(2) {
    width: 100%;

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      flex: 1;
    }
  }
}
