/** ***************************************************************************
 * Common / Organisms / Account / Base
 *************************************************************************** */

@use '~/src/styles/utilities.scss' as *;

.cn-c-account_overlay {
  border-radius: 4px;

  &.cn-m-signup {
    background-color: var(--cn-global--palette-white-smoke);
  }

  .profile-section-title {
    font: var(--cn-global--FontWeight-semibold) pxToRem(26px) var(--cn-global--FontFamily-default);
    margin-block-end: pxToRem(25px);
  }

  .account-status {
   font: var(--cn-global--FontWeight-regular) pxToRem(11px) var(--cn-global--FontFamily-default);
   margin-block-end: pxToRem(34px);
   letter-spacing: unset;
  }

  .cn-c-field {
    margin-block: pxToRem(11px);
  }

  .action-buttons {
    margin-block-start: 1.5rem;
    text-align: center;

    &.edit {
      margin-block-start: 1.875rem;

      .cn-u-button.cn-c-button {
        display: block;
        margin-block-end: 1.875rem;
      }

      .cancel-cta {
        color: var(--cn-global--palette-dark-blue);
      }
    }
  }

}
