/** ***************************************************************************
 * Digital / Organisms / Header / Navigation
 *************************************************************************** */

 @use "sass:map";
@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-navigation {
  --cn-c-navigation--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-navigation--Color: var(--cn-c-page--BackgroundColor);
  --cn-c-navigation--ColumnGap: 2.75em;  /* 55px */
  --cn-c-navigation--Font: var(--cn-global--Font-title--lg-bold);
  --cn-c-navigation--MarginBlock: #{pxToRem(55px)};


  /**
   * Close button
   */

  --cn-c-navigation__close--BorderColor: var(--cn-global--palette-white--49);
  --cn-c-navigation__close--Size: 1rem;  /* 16px */


  /**
   * Navigation items
   */

  --cn-c-navigation__items--MarginBlockStart: 1.5rem;
  --cn-c-navigation__language--MarginBlockStart: 2.5rem;  /* 40px */
  --cn-c-navigation__items--RowGap: #{pxToRem(20px)};


  /**
   * Navigation item
   */

  --cn-c-navigation__item--Color: var(--cn-global--palette-dark-blue);
  --cn-c-navigation__item--RowGap: .65em;  /* 13px */

  --cn-c-navigation__item-decoration--BackgroundColor: currentcolor;

  --cn-c-navigation__item--m-current--Color: var(--cn-global--palette-white);


  /**
   * Logo
   */

  --cn-c-navigation__logo--Height: 3.813rem;  /* 61px */


  @media all and (min-width: 48rem) {
    // --cn-c-navigation--BackgroundColor: transparent;
    --cn-c-navigation--Font: var(--cn-global--Font-title--md-regular);
  }

  @media all and (min-width: 48rem) {
    /* --cn-c-navigation__item--Color: var(--cn-global--palette-white); */
    --cn-c-navigation__item--m-current--Color: var(--cn-c-navigation__item--Color);
    --cn-c-navigation__item--RowGap: .5em;  /* 7px */
    --cn-c-navigation__item-decoration--BackgroundColor: var(--cn-global--palette-dark-blue);
  }
}


#cn-c-header__toggle:not(:checked) ~ .cn-c-navigation {
  opacity: 0;
  pointer-events: none;
  transition: none;
  visibility: hidden;

  @include dispatchToContextBreakpoints($grid-breakpoints) {
    opacity: 1;
    pointer-events: initial;
    visibility: visible;
  }
}

.cn-c-navigation {
  background-color: var(--cn-global--palette-dark-gray--60);
  color: var(--cn-global--palette-dark-blue);
  font: var(--cn-c-navigation--Font);

  position: fixed;
  inset: 0;
  margin-block-start: var(--cn-c-navigation--MarginBlock);
  transition: opacity .15s linear;

  @include dispatchToContextBreakpoints($grid-breakpoints) {
    position: initial;
    margin-block-start: unset;
  }

  @supports not (inset: 0) {
    bottom: 0;
    left: 0;
    padding-top: var(--cn-c-navigation--MarginBlock);
    right: 0;
    top: 0;

    @media all and (min-width: 48rem) {
      padding-top: 0
    }
  }


  /**
   * Close button
   * 1: reset
   */
  > label {
    align-self: end;
    border-radius: 50%;
    border: 1px solid var(--cn-c-navigation__close--BorderColor);
    box-sizing: content-box;  /* 1 */
    display: inline-block;
    /* height: var(--cn-c-navigation__close--Size); */
    padding: calc(var(--cn-c-navigation__close--Size) * 1.125);
    /* width: var(--cn-c-navigation__close--Size); */

    .cn-c-button.close-button {
      --cn-c-button--m-close--Background-Color: transparent;
      --cn-c-button--m-close--Color: var(--cn-global--palette-dark-grey);
      border-color: var(--cn-global--palette-dark-grey--49);
      padding: .93rem;
      border-radius: 50%;
      pointer-events: none;

      svg {
        width: 1.2rem;
      }
    }

    svg {
      display: block;  /* 1 */
    }

    @media all and (min-width: 48rem) {
      display: none;
    }

    @media all and not (pointer:coarse) {
      cursor: none;
    }
  }

  .cn-u-divider {
    position: absolute;
    left: var(--cn-global--content-grid--offsetsize);
    right: var(--cn-global--content-grid--offsetsize);
    width: unset;
    z-index: 10;

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      display: none;
    }
  }

  .content-wrapper {
    background-color: var(--cn-c-navigation--BackgroundColor);
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    // pointer-events: none;
    // margin-block-start: var(--cn-c-navigation--MarginBlock);

    > div {
      height: 100%;
      // overflow: auto;
      // overflow-x: hidden;
      padding-inline: var(--cn-global--content-grid--offsetsize);
      // padding-block-end: var(--cn-c-navigation--MarginBlock);
      pointer-events: auto;

      > .inner-wrapper {
        display: flex;
        flex-direction: column;
        gap: pxToRem(40px);
        height: 100%;
        // border-block-start: var(--cn-global--border--DarkTheme);

        .DIGITAL & {
          // gap: pxToRem(8px);
          gap: 0;
        }
        .E_COMMERCE & {
          justify-content: space-between;
        }

        > *:last-child {
          /*padding-block-end: var(--cn-c-navigation--MarginBlock);*/
          padding-block-end: pxToRem(20px);

          @include dispatchToContextBreakpoints($grid-breakpoints) {
            padding-block-end: unset;
          }
        }

        @include dispatchToContextBreakpoints($grid-breakpoints) {
          border-block-start: none;
        }
      }

      @include dispatchToContextBreakpoints($grid-breakpoints) {
        overflow: unset;
        padding-block-end: unset;
      }
    }

    @media all and (min-width: map.get($grid-breakpoints, sm)) {
      // background-color: unset;
      height: min(calc(512px - var(--cn-c-template_shared--top_nav--Height)), 100%);

      > div {
        height: unset;
        background-color: var(--cn-c-navigation--BackgroundColor);

        > *:first-child {
          padding-block-end: var(--cn-c-navigation--MarginBlock);
        }
      }
    }

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      overflow: unset;

      > div {
        > *:first-child {
          padding-block-end: unset;
        }
      }
    }
  }

  /**
   * Navigation items
   * 1: reset
   */
  .content-wrapper > div ul {
    display: flex;
    flex-direction: column;
    gap: var(--cn-c-navigation__items--RowGap);
    list-style-type: none;  /* 1 */
    // margin-block: var(--cn-c-navigation__items--MarginBlockStart) 0;
    // margin-inline: 0;  /* 1 */
    margin: 0;
    padding-inline: 0;  /* 1 */

    // @media all and (min-width: 48rem) {
    //   flex-direction: row;
    //   gap: var(--fluid-10-13);
    //   margin-block: 0;
    //   padding-inline-start: var(--fluid-13-18);
    // }

    &:not(.nav-expand-content) {
      // position: relative;

      @include dispatchToContextBreakpoints($grid-breakpoints) {
        position: unset;
        border-block-start: none;
        flex-wrap: wrap;
      }
    }

    @supports not (margin-block: 0 0) {
      margin: var(--cn-c-navigation__items--MarginBlockStart) 0 0 0;
    }

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      flex-direction: row;
    }
  }

  .content-wrapper > div ul.first-level {
    padding-block-start: pxToRem(37px);
    flex: 1;

    &:has(> .is-active) {
      > .cn-c-menu_item {
        visibility: hidden;
      }
    }

    .DIGITAL & {
      position: relative;

      @include dispatchToContextBreakpoints($grid-breakpoints) {
        position: unset;
      }
    }

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      padding-block-start: unset;

      &:has(> .is-active) {
        > .cn-c-menu_item {
          visibility: unset;
        }
      }
    }
  }

  .cta-ecommerce-app {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: pxToRem(8px);
    border-block: var(--cn-global--border--DarkTheme);

    .cn-c-header_heading {
      padding-inline-start: unset;
    }

    .cn-c-button {
      font-weight: var(--cn-global--FontWeight-semibold);
      font-size: pxToRem(13px);
    }
  }


  /**
   * Navigation item
   * 1: reset
   * 2: contain decoration
   */
  .content-wrapper > div > .inner-wrapper > ul li {
    &:not(.nav-back-link) {
      margin-inline-start: pxToRem(9px);
    }

    &.cn-m-current {
      pointer-events: none;
    }

    &.E_COMMERCE,
    &.DIGITAL {
      @include dispatchToContextBreakpoints($grid-breakpoints) {
        display: none;
      }
    }
  }


  /**
   * Logo
   */

  > svg {
    height: var(--cn-c-navigation__logo--Height);
    display: inline-block;
    align-self: end;

    @media all and (min-width: 48rem) {
      display: none;
    }
  }

  .change-language-wrapper {
    display: flex;
    flex-direction: column;
    gap: pxToRem(21px);
    z-index: 9;

    .title {
      font: var(--cn-global--FontWeight-medium) pxToRem(16px) var(--cn-global--FontFamily-default);
    }

    .cn-c-button.cn-c-button--language-selection {
      --cn-c-button--outline--Color: var(--cn-c-header--Color);
      --cn-c-button--outline--BackgroundColor: var(--cn-c-header--BackgroundColor);
      --cn-c-button--hover--Color: var(--cn-c-header--BackgroundColor);
      --cn-c-button--hover--BackgroundColor: var(--cn-c-header--Color);
      display: flex;
      width: pxToRem(48px);
    }

    @media all and (min-width: map.get($grid-breakpoints, sm)) {
      display: none;
    }
  }

  .language-selection {
    display: flex;
    align-items: center;
    gap: 1.063rem;
    margin-block-start: var(--cn-c-navigation__language--MarginBlockStart);
    padding-inline-end: .313rem;

    > a {
      font-size: 2.5rem;
      /* margin-inline-end: 1.063rem; */
      vertical-align: middle;
      color: var(--cn-global--palette-dark-blue--31);

      &.is-active {
        color: var(--cn-global--palette-dark-blue);
      }

      &:hover {
        color: var(--cn-global-common--palette-kiss);
      }

      @media all and (min-width: 48rem) {
        font: var(--cn-global--Font-label--sm-regular);
        margin-inline-end: var(--fluid-10-13);
        color: var(--cn-global--palette-prussian-blue);

        &.is-active {
          font: var(--cn-global--Font-label--sm-extrabold);
          color: var(--cn-global--palette-light-blue);
        }

        &:hover {
          color: var(--cn-global-common--palette-kiss);
        }
      }
    }

    @media all and (min-width: 48rem) {
      margin-block: 0;
      gap: 0;
      border-inline-end: 1px solid var(--cn-global--palette-light-blue--45);
    }
  }
}

.cn-c-navigation .cn-c-menu_item {
  --cn-c-menu_item__button--Color: var(--cn-c-navigation--Color);
}
