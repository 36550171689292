/** ***************************************************************************
 * Common / Organisms / Catalog Section
 *************************************************************************** */

@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-catalog_section {
}
