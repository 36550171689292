/******************************************************************************
 * Common / Organisms / Header
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-main_nav {
  --cn-c-main_nav--BackgroundColor: var(--cn-c-header--Color);
  --cn-c-main_nav--Color: var(--cn-c-header--BackgroundColor);

  /**
   * Context label
   */

  --cn-c-main_nav__context--Color: var(--cn-global--palette-red);
}

.cn-c-main_nav {
  --cn-c-menu_item--focus--BackgroundColor: var(--cn-c-main_nav--Color);
  background-color: var(--cn-c-main_nav--BackgroundColor);
  color: var(--cn-c-main_nav--Color);
  display: none;
  transition: height var(--cn-global--Duration--tn) linear;

  .cn-c-menu_item .cn-c-button {
    color: var(--cn-c-main_nav--Color);
  }

  .context-label {
    color: var(--cn-c-main_nav__context--Color);
  }
}

.cn-c-main_nav ul {
  display: flex;
  gap: pxToRem(56px);

  .cn-c-menu_item {
    position: relative;
    padding-inline-end: 0;
  }
}
