/** ***************************************************************************
 * Digital / Organisms / Header / Navigation
 *************************************************************************** */

 @use "sass:map";
 @use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-language_list {

  > .container {
    display: flex;
    gap: pxToRem(12px);

    @media all and (min-width: map.get($grid-breakpoints, sm)) {
      flex-direction: column;
      align-items: center;
      gap: pxToRem(13px);
      border-radius: 0 0 pxToRem(23px) pxToRem(23px);
      padding-block: pxToRem(18px);
      padding-inline: pxToRem(20px);
      transition: all var(--cn-global--Duration--tn) linear;
    }
  }

  .is-selected {
    background-color: var(--cn-c-header--Color);
    color: var(--cn-c-header--BackgroundColor);

    @media all and (min-width: map.get($grid-breakpoints, sm)) {
      background-color: var(--cn-c-header--BackgroundColor);
      color: var(--cn-c-header--Color);
      margin-block-end: pxToRem(5px);
    }
  }

  .cn-c-button {
    font: var(--cn-global--Font-label--xs-book);

    &:not(.is-selected) {
      @media all and (min-width: map.get($grid-breakpoints, sm)) {
        visibility: hidden;
        opacity: 0;
        transition: var(--cn-global--Duration--sm);
        width: pxToRem(48px);
      }
    }
  }

  .separator {
    width: 1px;
    height: pxToRem(20px);
    margin-block: auto;
    background-color: var(--cn-c-header--Color);

    @media all and (min-width: map.get($grid-breakpoints, sm)) {
      height: 1px;
      width: pxToRem(20px);
      visibility: hidden;
    }
  }

  &.is-open {
    overflow: visible;

    > .container {
      background-color: var(--cn-c-page--Color);
    }

    .cn-c-button {
      visibility: visible;
      opacity: 1;

      &:hover::before {
        content: none;
      }
    }

    .separator {
      visibility: visible;
    }
  }

  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    height: var(--cn-c-template_shared--top_nav--PositionY);
    z-index: 9;
    overflow: hidden;
    margin-inline: calc(-1 * pxToRem(20px));
  }
}
