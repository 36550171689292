/** ***************************************************************************
 * Common / Molecules / Image Wrapper
 *************************************************************************** */

@use '~/src/styles/utilities.scss' as *;

.cn-c-image_wrapper {
  position: relative;
  display: flex;

  img {
    object-fit: cover;
    width: 100%;
  }

  .mask-on-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: .33;
  }
}
