/** ***************************************************************************
 * Common / Organisms / Notifications
 *************************************************************************** */

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-notifications {

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: pxToRem(3px);
    padding-block: pxToRem(3px) pxToRem(23px);

    &.is-hidden {
      padding: 0;
    }
  }

  @media all and (min-width: map.get($grid-breakpoints, lg)) {
    position: fixed;
    bottom: 20px;
    top: 0;
    left: 0;
    z-index: 99;

    // height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    scrollbar-width: none;

    .container {
      gap: pxToRem(11px);
      padding-block-start: pxToRem(11px);
      padding-block-end: unset;
    }
  }
}
