/** ***************************************************************************
 * Common / Molecules / Context Selection
 *************************************************************************** */

@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-context_selection {

  &.cn-c-custom_select {
    height: 100%;
    width: 100%;
    border-block: var(--cn-global--border--DarkTheme);

    .custom_select-inner {
      border: none;
      height: 100%;

      &::after {
        border-color: var(--cn-c-header--Color);
      }

      .cn-c-header_heading {
        padding-inline-start: unset;
        display: flex;
        flex-direction: row;
        gap: 0.3125rem;
      }
    }

    .selected-option {
      display: flex;
      align-items: center;
      height: 100%;
      padding-inline: unset;
      // font-size: pxToRem(14px);

      @include dispatchToContextBreakpoints($grid-breakpoints) {
        padding-inline: pxToRem(10px);
      }
    }

    .dropdown {
      background-color: var(--cn-c-header--BackgroundColor);
      border-top-width: 2px;
    }

    .option:hover {
      background-color: var(--cn-c-page--AccentColor);
      // color: var(--cn-c-header--BackgroundColor);
    }

    .option-content {
      .cn-c-label {
        font-size: pxToRem(14px);
      }
    }

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      width: pxToRem(220px);
      border-block: unset;
    }
  }
}
