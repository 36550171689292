/******************************************************************************
 * Common / Atoms / Button / Outline
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-button--outline {
  --cn-c-button--outline--Color: var(--cn-c-page--Color);
  --cn-c-button--outline--BackgroundColor: transparent;
  --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);
  --cn-c-button--hover--BackgroundColor: var(--cn-c-button--outline--Color);
  --cn-c-button--PaddingBlock: var(--cn-c-button--outline--PaddingBlock, #{pxToRem(2px)});
  --cn-c-button--PaddingInline: var(--cn-c-button--outline--PaddingInline, #{pxToRem(13px)});
  --cn-c-button--BorderColor: var(--cn-c-button--outline--Color);

  // display: none;
  // position: relative;
  // align-items: center;

  display: flex;
  text-transform: uppercase;
  font: var(--cn-global--Font-label--xs-regular);
  letter-spacing: 1px;
  // width: 3rem;

  background-color: var(--cn-c-button--outline--BackgroundColor, transparent);
  color: var(
    --cn-c-button--outline--Color,
    var(--cn-c-button--BackgroundColor)
  );

  &.selected {
    --cn-c-button--outline--Color: var(--cn-c-page--BackgroundColor);
    --cn-c-button--outline--BackgroundColor: var(--cn-c-page--Color);
    --cn-c-button--hover--Color: var(--cn-c-button--outline--Color);
    --cn-c-button--hover--BackgroundColor: var(--cn-c-button--outline--BackgroundColor);
    --cn-c-button--BorderColor: var(--cn-c-button--outline--BackgroundColor);
  }

  &.inactive {
    --cn-c-button--outline--Color: var(--cn-c-page--Color);
    --cn-c-button--outline--BackgroundColor: var(--cn-global--palette-gainsboro);
    --cn-c-button--hover--Color: var(--cn-c-button--outline--Color);
    --cn-c-button--hover--BackgroundColor: var(--cn-c-button--outline--BackgroundColor);
    --cn-c-button--BorderColor: var(--cn-c-button--outline--BackgroundColor);

    cursor: initial;
  }

  &.common-action {
    --cn-c-button--outline--PaddingBlock: 0.943em;
    --cn-c-button--outline--PaddingInline: 1.615em;
    --cn-c-button--ColumnGap: 0.5rem;
    font: var(--cn-global--Font-label--sm-semibold);
    text-transform: none;
    letter-spacing: normal;
  }
}
