/******************************************************************************
 * Common / Atoms / Menu Item
 *****************************************************************************/

@use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-menu_item {
  --cn-c-menu_item__button--Color: var(--cn-c-page--BackgroundColor);
  --cn-c-menu_item__button--hover--before--BackgroundColor: var(--cn-c-page--AccentColor);
}

.cn-c-menu_item {
  --cn-c-button--icon-only--Dimension: #{pxToRem(16px)};

  // position: relative;
  display: flex;
  align-items: center;
  gap: pxToRem(12px);

  &.is-active {
    > .nav-expand-content {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
      // padding-block-end: var(--cn-c-navigation--MarginBlock);
      // overflow: unset;

      .DIGITAL & {
        overflow: visible;

        > div {
          margin-inline: calc(-1 * var(--cn-global--content-grid--offsetsize));

          @include dispatchToContextBreakpoints($grid-breakpoints) {
            margin-inline: unset;
          }
        }
      }

      @include dispatchToContextBreakpoints($grid-breakpoints) {
        overflow: auto;
        padding-block-end: unset;
      }
    }

    .nav-expand-link {
      span:not(.icon-wrapper) {
        position: relative;

        @include dispatchToContextBreakpoints($grid-breakpoints) {
          &::after {
            content: "";
            position: absolute;
            bottom: pxToRem(-19px);
            left: 0;
            z-index: 14;
            height: 1px;
            width: 100%;
            background-color: var(--cn-global--palette-white);
          }
        }
      }
    }
  }

  .nav-expand-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    transform: translateX(100%);
    padding: 0;
    padding-block-start: pxToRem(21px);
    transition:
      transform var(--cn-global--Duration--sm),
      visibility var(--cn-global--Duration--sm),
      opacity var(--cn-global--Duration--sm);
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    // pointer-events: none;

    > div {
      background-color: var(--cn-c-header--BackgroundColor);
      padding-inline: var(--cn-global--content-grid--offsetsize);
      padding-block-end: var(--cn-c-navigation--MarginBlock);
      pointer-events: auto;
    }

    .nav-item {
      &:not(:last-child) {
        // border-bottom: solid 1px $nav-level-border-color;
      }
    }

    .nav-link {
      // background-color: $nav-level-background-color;
    }

    .nav-back-link {

      .back-link-wrapper {
        display: flex;
        align-items: center;
        gap: pxToRem(12px);
        cursor: pointer;
        padding-block-end: pxToRem(13px);

        .cn-c-button {
          &:hover, &:active, &:focus-visible {
            &::before {
              content: unset;
            }
          }

          &:focus-visible {
            outline: revert;
          }
        }

      }

      .cn-c-button {
        --cn-c-button--hover--BackgroundColor: transparent;
        justify-content: flex-start;
      }

      svg {
        transform: rotate(180deg);
      }

      @include dispatchToContextBreakpoints($grid-breakpoints) {
        display: none;
      }
    }

    .brand-logo-wrapper {
      display: none;
      padding-block-end: pxToRem(38px);
    }

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      height: unset;
      transform: unset;
      padding-block-start: 0;
      margin-block-start: var(--cn-c-template_shared--top_nav--PositionY);
      background-color: var(--cn-c-header--BackgroundColor);

      > div {
        // border-block-start: var(--cn-global--border--DarkTheme);
        padding-block-end: pxToRem(59px);

        > .inner-wrapper {
          @include dispatchToContextBreakpoints($grid-breakpoints) {
            padding-block-start: pxToRem(50px);
            border-block-start: var(--cn-global--border--DarkTheme);
          }
        }
      }

      ul {
        justify-content: space-between;

        li {
          margin-inline-start: 0 !important;
          padding-inline: 0;
          // flex-basis: 20%;

          a {
            font: var(--cn-global--Font-label--xl-semibold);
          }

          .cn-c-button {
            &:hover, &.is-active {
              &::before {
                content: '';
                position: absolute;
                top: unset;
                transform: none;
                bottom: pxToRem(-15px);
                left: 0;
                height: 1px;
                width: 100%;
                background-color: var(--cn-global--palette-white);
              }
            }
          }
        }
      }
    }

    @media all and (min-width: map.get($grid-breakpoints, xl)) {
      .E_COMMERCE & {
        .brand-logo-wrapper {
          display: block;
        }
      }
    }
  }

  .nav-expand-link {
    display: flex;
    justify-content: space-between;
  }

  .cn-c-button {
    font: var(--cn-global--Font-label--md-medium);
    color: var(--cn-c-menu_item__button--Color);
    padding: 0;
    column-gap: pxToRem(12px);

    > .icon-wrapper {
      block-size: pxToRem(17.6px);
      pointer-events: none;
      flex: 1;
    }

    &:hover, &:active, &:focus-visible, &.is-active {
      background-color: transparent;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: pxToRem(-10px);
        width: pxToRem(2px);
        height: pxToRem(13px);
        background-color: var(--cn-c-menu_item__button--hover--before--BackgroundColor);
        border-radius: 2px;
      }
    }

    &:focus-visible {
      outline: none;

      &::before {
        background-color: var(--cn-c-menu_item--focus--BackgroundColor, var(--cn-c-page--BackgroundColor));
      }
    }
  }

  @include dispatchToContextBreakpoints($grid-breakpoints) {
    padding-inline: pxToRem(10px);

    .nav-expand-link {
      flex-direction: row-reverse;
    }
  }
}
