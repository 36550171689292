/******************************************************************************
 * Common / Atoms / Add to Cart CTA
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-add_to_cart_cta {
  --cn-c-add_to_cart--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-add_to_cart--Color: var(--cn-c-page--BackgroundColor);

  background-color: var(--cn-c-add_to_cart--BackgroundColor);
  color: var(--cn-c-add_to_cart--Color);
  border-radius: 100vmax;
  transition: background-color var(--cn-global--Duration--xs) linear;
  padding-block: pxToRem(17px);
  padding-inline: 1.615em;
  display: flex;
  justify-content: space-evenly;
  width: pxToRem(282px);
  height: pxToRem(64px);

  > div {
    display: flex;
    align-items: center;
  }

  .field {
    display: none;
  }

  .amount-selection {
    height: 100%;
    column-gap: pxToRem(3px);
    // border-inline-end: var(--cn-global--border);
    // padding-inline-end: pxToRem(7px);

    .cn-c-button--icon-only {
      --cn-c-button--icon-only--Dimension: #{pxToRem(22px)};
      --cn-c-button--icon-only--Padding: #{pxToRem(4px)};
    }

    svg {
      block-size: pxToRem(9.6px);
    }

    .item-amount {
      width: pxToRem(15px);
    }
  }

  .vertical-separator {
    width: 1px;
    background-color: var(--cn-global--border--Color--DarkTheme);
    margin-inline: pxToRem(7px);
  }

  .cn-c-add_to_cart {

    .cart {
      --cart: var(--cn-c-page--BackgroundColor);
      position: relative;

      > svg {
        position: relative;
        block-size: 1.3125rem;
        transition: fill var(--cn-global--Duration--sm) ease;
      }

      &:after {
          content: '';
          position: absolute;
      }

      &:after {
          background: var(--cart);
          left: 2px;
          bottom: 1px;
          top: 6px;
          right: 2px;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          transform: scaleY(var(--fill, 0));
          transition: transform var(--cn-global--Duration--sm) ease var(--fill-d);
      }

      svg {
        z-index: 1;
        vertical-align: top;
        position: relative;
        fill: none;
        stroke: var(--cart);
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;

        polyline {
          &:last-child {
            stroke: var(--cn-c-page--AccentColor);
            stroke-dasharray: 18px;
            stroke-dashoffset: var(--offset, 18px);
            transition: stroke-dashoffset var(--cn-global--Duration--sm) ease var(--offset-d);
          }
        }
      }
    }

    &.cn-c-button {
      --cn-c-button--PaddingInline: #{pxToRem(13px)};
      --cn-c-button--PaddingBlock: unset;
      font-family: var(--cn-global--FontFamily-default);
      font-size: pxToRem(20px);
      font-weight: var(--cn-global--FontWeight-semibold);
      column-gap: pxToRem(17px);

      > .cart {
        block-size: pxToRem(21px);
        border-radius: unset;
        padding: unset;
        background-color: unset;
        color: unset;
      }
    }
  }

  .cn-c-button {
    color: var(--cn-c-add_to_cart--Color);
  }

  &.is-loading {
    .cn-c-add_to_cart {
      --fill: 1;
      --fill-d: #{var(--cn-global--Duration--sm)};
      --offset: 0;
      --offset-d: #{var(--cn-global--Duration--md)};

      .cart {
        animation: cart var(--cn-global--Duration--xl) linear forwards var(--cn-global--Duration--sm);
      }
    }
  }

  &:hover,
  &.is-loading {
    --cn-c-add_to_cart--BackgroundColor: var(--cn-c-page--AccentColor);
  }
}


@keyframes cart {
  40% {
    transform: scale(.9);
  }
  30%, 50% {
    transform: none;
  }
}
