/** ***************************************************************************
 * Common / Atoms / Forms / Widgets / Select
 *************************************************************************** */

select {
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2013%207%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20stroke%3D%22%23333%22%20stroke-width%3D%221%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20fill%3D%22none%22%20d%3D%22M0.5%2C0.5l6%2C6l6%2C-6%22%2F%3E%3C%2Fsvg%3E");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 1em;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  &:focus:active {
    background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2013%207%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20stroke%3D%22%23333%22%20stroke-width%3D%221%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20fill%3D%22none%22%20d%3D%22M12.5%2C6.5l-6%2C-6l-6%2C6%22%2F%3E%3C%2Fsvg%3E");
  }
}
