/** ***************************************************************************
 * Ecommerce / Organisms / Footer
 *************************************************************************** */

 @use "sass:map";
@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-footer {
  --cn-c-footer--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-footer--Color: var(--cn-c-page--BackgroundColor);

  /**
   * First line
   */

  --cn-c-footer__first_line--ColumnGap: clamp(var(--cn-global--content-grid--guttersize), 7.57vw, 6.8125rem);
  --cn-c-footer__first_line--GridAutoFlow: row;


  /**
   * Intro
   */

  --cn-c-footer__intro--MaxInlineSize: none;


  /**
   * Newsletter form
   */

  --cn-c-footer__newsletter--MaxInlineSize: 25.5rem;  /* 408px */


  background-color: var(--cn-c-footer--BackgroundColor);
  color: var(--cn-c-footer--Color);


  /** Large: 1024px */
  @media all and (min-width: map.get($grid-breakpoints, lg)) {
    --cn-c-footer__first_line--GridAutoFlow: column;
    --cn-c-footer__intro--MaxInlineSize: 14.125rem;  /* 226px */
  }
}

.footer-container {
  padding-inline: var(--cn-c-footer--PaddingInline);
  padding-block: pxToRem(54px) pxToRem(32px);
  max-width: var(--cn-c-footer--MaxWidth);
  margin-inline: auto;

  .cn-c-socials {
    .cn-c-button {
      --cn-c-socials--Color: var(--cn-c-footer--BackgroundColor);
      --cn-c-socials--BackgroundColor: var(--cn-c-footer--Color);
      --cn-c-button--BorderColor: var(--cn-c-footer--Color);
      --cn-c-button--hover--Color: var(--cn-c-footer--BackgroundColor);
      --cn-c-button--hover--BackgroundColor: var(--cn-c-footer--Color);
    }
  }

  .first-line {
    display: grid;
    grid-auto-flow: var(--cn-c-footer__first_line--GridAutoFlow);
    grid-column-gap: var(--cn-c-footer__first_line--ColumnGap);
    grid-column: 1 / -1;
    justify-content: space-between;
    justify-self: stretch;
    margin-block-end: pxToRem(46px);

    .intro {
      max-inline-size: var(--cn-c-footer__intro--MaxInlineSize);

      .cn-c-socials {
        margin-block-start: pxToRem(37px);
        margin-block-end: pxToRem(36px);
      }

      .cn-c-label {
        line-height: normal;

        > p {
          font-weight: unset;
          line-height: unset;
        }
      }
    }

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: pxToRem(5px);
      margin-block-start: pxToRem(10px);
      padding-inline-start: 0;

      .cn-c-button {
        --cn-c-button--PaddingInline: 0;
        --cn-c-button--PaddingBlock: 0;
        --cn-c-button--no-frame--Color: var(--cn-c-footer--Color);
        display: unset;
        font: var(--cn-global--Font-label--xs-book);
      }
    }

    .cn-c-newsletter {
      max-inline-size: var(--cn-c-footer__newsletter--MaxInlineSize);

      form {

        > div:first-child {
          display: flex;
          gap: pxToRem(10px);
          justify-content: space-between;
          padding-block: pxToRem(10px);
          margin-block-start: pxToRem(10px);
          border-block-end: 1px solid var(--cn-c-footer--Color);

          > input {
            box-shadow: none;
            border: none;
            border-radius: unset;
            background-color: transparent;
            padding-inline: 0;
            color: var(--cn-c-footer--Color);
            flex: 1;
            padding-top: pxToRem(25px);

            &::placeholder {
              color: var(--cn-c-footer--Color);
              opacity: .69;
            }

            &:focus-visible {
              outline: none;
            }
          }
        }
      }

      .cn-c-button {
        --cn-c-button--hover--BackgroundColor: var(--cn-c-footer--Color);
        --cn-c-button--hover--Color: var(--cn-c-footer--BackgroundColor);
        font-weight: var(--cn-global--FontWeight-semibold);

        &[disabled] {
          --cn-c-button--BackgroundColor: var(--cn-c-footer--Color);
          --cn-c-button--Color: var(--cn-c-footer--BackgroundColor);
          opacity: .85;
        }
      }

      .cn-c-field {
        margin-block: pxToRem(37px) pxToRem(14px);

        .form-field {
          > label {
            align-items: flex-start;

            > span {
              flex: 1;
            }

            input[type="checkbox"] {
              border-color: var(--cn-c-footer--Color);

              &::before {
                background-color: var(--cn-c-footer--Color);
              }
            }
          }
        }
      }

      .privacy-policy {
        font-size: pxToRem(12px);
        opacity: .69;

        a {
          text-decoration: underline;
        }
      }
    }

    .cn-c-header_heading {
      padding-inline-start: unset;
      display: flex;
      gap: pxToRem(7px);
      margin-block-start: pxToRem(10px);
      margin-block-end: pxToRem(25px);
    }

    .title {
      font: var(--cn-global--FontWeight-medium) pxToRem(20px) var(--cn-global--FontFamily-default);
      margin-block-start: pxToRem(10px);
    }

    @media all and (min-width: map.get($grid-breakpoints, lg)) {
      margin-block-end: pxToRem(74px);

      .title {
        font: var(--cn-global--FontWeight-medium) pxToRem(24px) var(--cn-global--FontFamily-default);
        margin-block-start: unset;
      }

      ul {
        gap: pxToRem(10px);
        margin-block-start: revert;

        .cn-c-button {
          font: var(--cn-global--Font-label--sm-book);
        }
      }

      .intro {
        .cn-c-socials {
          display: none;
        }
      }
    }
  }

  .second-line {
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: pxToRem(28px);

    .cn-c-socials {
      display: none;

      @media all and (min-width: map.get($grid-breakpoints, lg)) {
        display: flex;
        gap: pxToRem(22px);
      }
    }

    .cn-c-socials,
    .cn-c-credits {
      flex: 1;
    }

    .cn-c-credits {
      justify-content: flex-end;
    }

    @media all and (min-width: map.get($grid-breakpoints, lg)) {
      flex-direction: row;
      gap: unset;
    }
  }

  @media all and (min-width: map.get($grid-breakpoints, md)) {
    padding-block: pxToRem(82px) pxToRem(19px);
  }
}

.cn-c-footer_banner {
  background-color: var(--cn-global--palette-unbleached);
  color: var(--cn-c-footer--BackgroundColor);
  text-align: center;
  padding-block: pxToRem(13px);

  @media all and (min-width: map.get($grid-breakpoints, md)) {
    .cn-c-label.semibold {
      font-size: 16px;
    }
  }
}
