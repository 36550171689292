/** ***************************************************************************
 * Common / Organisms / Profile / Delete
 *************************************************************************** */

@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-profile-overlay.cn-m-delete {
  padding-block-end: 2.25rem;

  .cn-c-notification_card {
    width: 100%;
    border-radius: 4px;
    margin-block-start: pxToRem(30px);
  }
}
