/******************************************************************************
 * Digital / Atoms / Magazine article descriptor
 *****************************************************************************/

 @use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-article_descriptor {
  /**
   * Category
   */

  --cn-c-article_descriptor__category--FontWeight: var(--cn-global--FontWeight-bold);
  --cn-c-article_descriptor__category--MarginBlockStart: 0.05em;

  /**
   * Title
   */

  --cn-c-article_descriptor__title--LineHeight: 1.45;

  /**
   * Section
   */

  --cn-c-article_descriptor__section--FontSize: 0.8em;
  --cn-c-article_descriptor__section--FontWeight: var(--cn-global--FontWeight-semibold);
  --cn-c-article_descriptor__section--MarginBlockStart: 1.125em;
}



/**
 * Component
 */

.cn-c-article_descriptor {
  display: flex;
  flex-direction: column;
}



/**
 * Category
 */

.cn-c-article_descriptor__category {
  font-weight: var(--cn-c-article_descriptor__category--FontWeight);
  margin-block-start: var(--cn-c-article_descriptor__category--MarginBlockStart);
}



/**
 * Title
 */

.cn-c-article_descriptor__title {
  display: none;
  line-height: var(--cn-c-article_descriptor__title--LineHeight);

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    display: block
  }
}



/**
 * Section
 */

.cn-c-article_descriptor__section {
  font-size: var(--cn-c-article_descriptor__section--FontSize);
  font-weight: var(--cn-c-article_descriptor__section--FontWeight);
  margin-block-start: var(--cn-c-article_descriptor__section--MarginBlockStart);
}
