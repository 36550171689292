/** ***************************************************************************
 * Common / Organisms / Header
 *************************************************************************** */

 @use "sass:map";
@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-header {
  --cn-c-header--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-header--Color: var(--cn-c-page--BackgroundColor);
  --cn-c-link--Color: var(--cn-c-header--Color);
}

.cn-c-header {
  > .top_nav-container {
    background-color: var(--cn-c-header--BackgroundColor);
    color: var(--cn-c-header--Color);
  }

  .cn-c-top_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.is-open {
    .top_nav-container {
      position: fixed;
      top: var(--cn-c-template_shared--top_nav--OffsetHeight);
      width: 100%;
      z-index: 100;
    }

    // .cn-c-top_nav {
    //   border-block-end: var(--cn-global--border--DarkTheme);
    // }
  }
}

.cn-c-header .row-start {
  display: flex;
  align-items: center;

  .cn-c-button {
    --cn-c-button--icon-only--Dimension: 1.813rem;
    --cn-c-button--hover--BackgroundColor: var(
      --cn-c-button--BackgroundColor
    );
  }

  label.cn-c-button {
    z-index: 9;
  }
}

.cn-c-header > .top_nav-container .row-end {
  display: flex;
  align-items: center;
  gap: pxToRem(44px);
}

.cn-c-header .action-buttons {
  display: flex;
  align-items: center;
  gap: pxToRem(15px);

  > div {
    position: relative;
  }

  .cn-c-button {
    --cn-c-button--no-frame--Color: var(--cn-c-header--Color);
    --cn-c-button--outline--Color: var(--cn-c-header--Color);
    --cn-c-button--hover--BackgroundColor: transparent;

    &.cn-c-button--no-frame {
      --cn-c-button--icon-only--PaddingBlock: #{pxToRem(2px)};
      --cn-c-button--icon-only--PaddingInline: 0;
    }

    &:hover, &:active, &:focus-visible, &.is-active {

      &::before {
        content: "";
        position: absolute;
        bottom: pxToRem(-11px);
        width: pxToRem(13px);
        height: pxToRem(2px);
        background-color: var(--cn-c-page--AccentColor);
        left: 0;
        right: 0;
        margin-inline: auto;
        border-radius: 2px;
      }
    }

    &:focus-visible {
      outline: none;
      &::before {
        background-color: var(--cn-c-page--BackgroundColor);
      }
    }
  }

  .cn-c-badge {
    position: absolute;
    bottom: -2px;
    right: 0;
  }

  @media screen and (min-width: map.get($grid-breakpoints, sm)) {
    padding-inline-start: pxToRem(38px);
  }

  @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
    border-inline-start: 1px solid var(--cn-c-header--Color);
    gap: pxToRem(28px);
  }
}

.row-end .cn-c-language_list {
  display: none;

  .cn-c-button.cn-c-button--language-selection {
    --cn-c-button--PaddingBlock: #{pxToRem(2px)};
    --cn-c-button--PaddingInline: #{pxToRem(12px)};
    --cn-c-button--BackgroundColor: transparent;
    --cn-c-button--BorderColor: var(--cn-c-header--Color);
    --cn-c-button--outline--BackgroundColor: var(--cn-c-header--BackgroundColor);

    position: relative;
    align-items: center;
    font: var(--cn-global--Font-label--xs-book);
    letter-spacing: 1px;

    &:hover, &:active, &:focus-visible {
      &::before {
        bottom: pxToRem(-15px);
      }
    }

    &:not(.is-selected) {
      --cn-c-button--hover--Color: var(--cn-c-header--BackgroundColor);
      --cn-c-button--hover--BackgroundColor: var(--cn-c-header--Color);
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, sm)) {
    display: block;
  }
}

.cn-c-header.DIGITAL > .top_nav-container .row-end {
  .action-buttons .cn-c-language_list {
    display: none;
  }
}

.cn-c-header .cn-c-search_bar {
  visibility: hidden;
  position: absolute;
  top: var(--cn-c-template_shared--top_nav--PositionY);
  left: 0;
  right: 0;
  z-index: 12;
  opacity: 0;
  transition: all var(--cn-global--Duration--tn) linear;

  &.is-open {
    visibility: visible;
    opacity: 1;
  }
}

.cn-c-header .cn-c-navigation {
  --cn-c-navigation--BackgroundColor: var(--cn-c-header--BackgroundColor);
  --cn-c-navigation--Color: var(--cn-c-header--Color);
}

@media screen and (min-width: map.get($grid-breakpoints, xl)) {
  .cn-c-header.E_COMMERCE {
    .cn-c-top_nav {
      justify-content: flex-end;
    }

    > .top_nav-container .row-start > *:not(nav) {
      display: none;
    }

    .cn-c-header_heading {
      display: flex;
      flex-direction: row;
      gap: pxToRem(5px);
      padding-inline-start: 0;
    }

    .action-buttons {
      border-inline-start: 1px solid var(--cn-c-header--Color);
      gap: pxToRem(28px);
      height: pxToRem(32px);
    }

    .cn-c-main_nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: map.get($grid-breakpoints, xxl)) {
  .cn-c-header .cn-c-top_nav {
    justify-content: flex-end;
  }

  .cn-c-header.DIGITAL {
    > .top_nav-container .row-start {
      visibility: visible;
      justify-content: space-between;
      width: 100%;

      > .cn-c-button {
        display: none;
      }
    }

    .cn-c-header_heading {
      display: flex;
      flex-direction: row;
      gap: pxToRem(5px);
      padding-inline-start: 0;
    }

    .cn-c-top_nav .cn-c-digital_magazines,
    .cn-c-top_nav .cta-ecommerce-app {
      display: none;
    }
  }

  .cn-c-main_nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
