/** ***********************************************************************************
 * Ecommerce / Atoms / Typography / Label
 *********************************************************************************** */

.cn-c-label {
  font: var(--cn-global--Font-label--xs-regular);

  &.small {
    font: var(--cn-global--Font-label--sm-regular);
  }

  &.book {
    font: var(--cn-global--Font-label--xs-book);
  }

  &.light {
    font: var(--cn-global--Font-label--md-light);
  }

  &.semibold {
    font: var(--cn-global--Font-label--xs-semibold);

    &.small {
      font: var(--cn-global--Font-label--sm-semibold);
    }

    &.medium {
      font: var(--cn-global--Font-label--md-semibold);
      line-height: var(--cn-global--LineHeight-md);
    }

    &.large {
      font: var(--cn-global--Font-label--lg-semibold);
    }
  }

  &.regular {
    font: var(--cn-global--Font-label--md-regular);
  }

  &.medium {
    font: var(--cn-global--Font-label--md-medium);
  }

}
