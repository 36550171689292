/******************************************************************************
 * Common / Atoms / Table
 *****************************************************************************/

 @use "sass:map";@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

table {
  --cn-c-table--BorderColor: var(--cn-global--palette-black--30);

  /**
   * Caption
   */

  --cn-c-table__caption--FontSize: 1.625em;
  --cn-c-table__caption--FontWeight: var(--cn-global--FontWeight-semibold);
  --cn-c-table__caption--MarginBlockEnd: 1em;

  /**
   * Header
   */

  --cn-c-table__header--BackgroundColor: var(--cn-global--palette-black);
  --cn-c-table__header--Color: var(--cn-global--palette-white);
  --cn-c-table__header--FontWeight: var(--cn-global--FontWeight-medium);
  --cn-c-table__header--PaddingBlock: 1.125em;

  /**
   * Cell
   */

  --cn-c-table__cell--PaddingBlock: 1em;
  --cn-c-table__cell--PaddingInline: 1em;

  /**
   * Link
   */

  --cn-c-table__link--hover--Color: var(--cn-global--palette-red);


  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    --cn-c-table__cell--PaddingInline: 1.5em;
  }
}



/**
 * Caption
 */
table > caption {
  font-size: var(--cn-c-table__caption--FontSize);
  font-weight: var(--cn-c-table__caption--FontWeight);
  margin-block-end: var(--cn-c-table__caption--MarginBlockEnd);
  text-align: start;
}



/**
 * Component
 * 1: equalize column widths
 */
table {
  table-layout: fixed;  /* 1 */
  inline-size: 100%;  /* 1 */
  border-collapse: collapse;
  border: 1px solid var(--cn-c-table--BorderColor);
}



/**
 * Header
 * 1: contain border
 */

thead {
  background-color: var(--cn-c-table__header--BackgroundColor);
  color: var(--cn-c-table__header--Color);
  font-weight: var(--cn-c-table__header--FontWeight);
}

thead th {
  border: 1px solid var(--cn-c-table__header--BackgroundColor);
  font-weight: inherit;
  padding-block: var(--cn-c-table__header--PaddingBlock);
  position: relative;  /* 1 */
  text-align: start;
}

thead th:nth-child(n+2):not(:empty)::before {
  background-color: var(--cn-c-table__header--Color);
  border-radius: 100vmax;
  box-sizing: content-box;
  content: "";
  display: block;
  inline-size: 1px;
  inset-block: var(--cn-c-table__cell--PaddingBlock);
  inset-inline-start: -0.5px;
  position: absolute;
}



/**
 * Body
 */

tbody :is(td, th) {
  border: 1px solid var(--cn-c-table--BorderColor);
}

tbody th {
  font-weight: var(--cn-c-table__header--FontWeight);
  text-align: start;
}



/**
 * Cell
 */

th,
td {
  padding-block: var(--cn-c-table__cell--PaddingBlock);
  padding-inline: var(--cn-c-table__cell--PaddingInline);
}



/**
 * Paragraph
 */

table p {
  font-size: inherit;
  line-height: inherit;
}



/**
 * Link
 */

table a {
  text-decoration: underline;
  text-decoration-thickness: 1px;

  &:hover {
    color: var(--cn-c-table__link--hover--Color);
    text-decoration: none;
  }
}
