/** ***************************************************************************
 * Ecommerce / Organisms / Newsletter
 *************************************************************************** */

@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-newsletter {
  form {

    > .email-wrapper {
      display: flex;
      gap: pxToRem(10px);
      justify-content: space-between;
      padding-block: pxToRem(10px);
      margin-block-start: pxToRem(10px);
      border-block-end: 1px solid var(--cn-c-footer--Color);

      > input {
        font: var(--cn-global--Font-label--xs-book);
        box-shadow: none;
        border: none;
        border-radius: unset;
        background-color: transparent;
        padding-inline: 0;
        color: var(--cn-c-footer--Color);
        flex: 1;
        padding-top: pxToRem(25px);

        &::placeholder {
          color: var(--cn-c-footer--Color);
          opacity: .69;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  .cn-c-button {
    --cn-c-button--hover--BackgroundColor: var(--cn-c-footer--Color);
    --cn-c-button--hover--Color: var(--cn-c-footer--BackgroundColor);
    font-weight: var(--cn-global--FontWeight-semibold);
  }

  .privacy-policy {
    font-size: pxToRem(12px);
    opacity: .69;

    a {
      text-decoration: underline;
    }
  }
}
