/******************************************************************************
 * Common / Organisms / Search Bar
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-search_bar {
  padding-block: pxToRem(40px) pxToRem(67px);
  background-color: var(--cn-c-page--BackgroundColor);
  color: var(--cn-c-page--Color);
  z-index: 9;

  > .container {

    .sr-only {
      display: none;
    }

    .input-wrapper {
      border-block: 2px solid var(--cn-global--border--Color);
      border-inline: unset;
      border-radius: unset;
      padding-inline: unset;
      display: flex;
      align-items: center;
      flex-direction: row;
      height: pxToRem(53px);

      > svg {
        block-size: pxToRem(18px);
      }

      &.cn-c-field input[type="search"] {
        box-shadow: none;
        border-radius: unset;
        background-color: transparent;
        font: var(--cn-global--FontWeight-regular) pxToRem(18px) var(--cn-global--FontFamily-book);
        height: 100%;
        width: 100%;
        flex: 1;
        padding-inline: pxToRem(11px);

        &::placeholder {
          font: var(--cn-global--FontWeight-regular) pxToRem(18px) var(--cn-global--FontFamily-book);
          opacity: .73;
        }

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
          appearance: none;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    .reset-button,
    .submit-button {
      --cn-c-button--icon-only--Dimension: 1.9375rem;
      --cn-c-button--icon-only--Padding: unset;
    }

    .reset-button {
      --cn-c-button--BackgroundColor: var(--cn-c-page--Color);
      --cn-c-button--hover--BackgroundColor: var(--cn-c-page--Color);
      margin-inline-end: pxToRem(10px);

      svg {
        block-size: pxToRem(16px);
      }
    }

    .submit-button {
      --cn-c-button--hover--BackgroundColor: var(--cn-c-page--AccentColor);

      svg {
        block-size: pxToRem(20px);
      }
    }
  }

  .context-selection {
    // height: pxToRem(53px);
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: pxToRem(11px);
    margin-block-start: pxToRem(25px);

    .cn-c-label {
      font-size: pxToRem(14px);
    }

    .custom_select-inner {
      label {
        display: none;
      }
    }
  }

  &.global {
    background-color: var(--cn-c-header--BackgroundColor);
    color: var(--cn-c-header--Color);

    .input-wrapper {
      border-block-color: var(--cn-global--palette-white--80);

      &:focus-within {
        border-color: unset;
      }

      input[type="search"] {
        color: var(--cn-c-header--Color);

        &::placeholder {
          color: var(--cn-c-header--Color);
        }
      }

      &.cn-c-field input[type="search"]::-webkit-search-cancel-button {
        height: pxToRem(13px);
        width: pxToRem(13px);
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667' d='M18 2 2 18M2 2l16 16'/%3E%3C/svg%3E%0A");
        background-size: pxToRem(13px) pxToRem(13px);
        cursor: pointer;
      }
    }

    .context-selection {
      display: flex;
    }
  }

  @include dispatchToContextBreakpoints($grid-breakpoints) {
    padding-block: pxToRem(99px) pxToRem(95px);

    > .container {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .input-wrapper {
        height: pxToRem(65px);
        flex: 1;

        &:focus-within {
          border-color: unset;
        }

        &.cn-c-field input[type="search"] {
          font: var(--cn-global--FontWeight-regular) pxToRem(28px) var(--cn-global--FontFamily-book);
          padding-inline: pxToRem(27px) pxToRem(24px);

          &::placeholder {
            font: var(--cn-global--FontWeight-regular) pxToRem(28px) var(--cn-global--FontFamily-book);
          }
        }

        > svg {
          block-size: pxToRem(27px);
        }
      }
    }

    .context-selection {
      width: unset;
      height: pxToRem(65px);
      padding-inline-start: pxToRem(16px);
      margin-block-start: unset;
      border-inline-start: 1px solid var(--cn-c-header--Color);
      border-block: 2px solid var(--cn-global--palette-white--80);
      flex-direction: row;
      align-items: center;
      gap: pxToRem(9px);

      .cn-c-label {
        font-size: pxToRem(16px);
      }
    }

    &.global {
      padding-block: pxToRem(62px) pxToRem(85px);

      .submit-button {
        margin-inline-end: pxToRem(27px);
      }
    }

    .reset-button {
      margin-inline-end: pxToRem(16px);
    }
  }
}
