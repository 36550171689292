/** ***********************************************************************************
 * Digital / Organisms / CMS plugins / Product call to action
 *********************************************************************************** */

 @use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;
@use "~/components/02-ecommerce/02-molecules/product_card/product_card.scss";
@use "~/components/02-ecommerce/02-molecules/variant/variant.scss";
@use "~/components/02-ecommerce/02-molecules/price/price.scss";


.cn-c-product_cta {
  --cn-c-product_cta--BackgroundColor: var(--cn-global--palette-white-smoke);
  --cn-c-product_cta--BorderRadius: .25em;
  --cn-c-product_cta--FontSize: 1rem;
  --cn-c-product_cta--PaddingBlock: 3em;
  --cn-c-product_cta--PaddingInlineStart: 2.5em;
  --cn-c-product_cta--TemplateColumns: repeat(2, 1fr);
  --cn-c-product_cta--ColumnGap: var(--cn-global--content-grid--guttersize);

  /**
   * Product card
   */

  --cn-c-product_cta__card--BorderRadius: var(--cn-c-product_cta--BorderRadius);
  --cn-c-product_cta__card--GridColumn: 1 / -1;
  --cn-c-product_cta__card--MaxInlineSize: 18.75em;  /* 300px */
  --cn-c-product_cta__card_title--Font: var(--cn-global--FontWeight-semibold) 1.125rem var(--cn-global--FontFamily-default);

  /**
   * Footer
   */

  --cn-c-product_cta__footer--GridColumn: 5 / -1;

  /**
   * Button
   */

  --cn-c-product_cta__button--InsetBlock: 9.5rem var(--cn-c-product_cta--PaddingBlock);

  /** Small: 600px */
  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    --cn-c-product_cta--TemplateColumns: repeat(7, 1fr);
    --cn-c-product_cta__card--GridColumn: 1 / span 4;
  }

  /** Large: 1024px */
  @media all and (min-width: map.get($grid-breakpoints, lg)) {
    --cn-c-product_cta__card--GridColumn: 1 / span 3;
  }
}



/**
 * Component
 * 1: extend background to the right
 * 2: exclude inline padding from grid column calculations
 */

.cn-c-product_cta {
  background-color: var(--cn-c-product_cta--BackgroundColor);
  border-radius: var(--cn-c-product_cta--BorderRadius);
  box-shadow: 0 0 0 100vmax var(--cn-c-product_cta--BackgroundColor);  /* 1 */
  clip-path: inset(0 -100vmax 0 0 round var(--cn-c-product_cta--BorderRadius));  /* 1 */
  display: grid;
  font-size: var(--cn-c-product_cta--FontSize);
  grid-column-gap: var(--cn-c-product_cta--ColumnGap);
  grid-template-columns: var(--cn-c-product_cta--TemplateColumns);
  margin-inline-start: calc(-1 * var(--cn-c-product_cta--PaddingInlineStart));  /* 2 */
  padding-block: var(--cn-c-product_cta--PaddingBlock);
  padding-inline-start: var(--cn-c-product_cta--PaddingInlineStart);
  position: relative;

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {

  }
}



/**
 * Product card
 */

.cn-c-product_cta > .cn-c-product_card {
  --cn-c-product_card__image--BorderRadius: var(--cn-c-product_cta__card--BorderRadius);
  --cn-c-product_card__title--Font: var(--cn-c-product_cta__card_title--Font);
  grid-column: var(--cn-c-product_cta__card--GridColumn);
  justify-self: start;
  max-inline-size: var(--cn-c-product_cta__card--MaxInlineSize);
}




/**
 * Footer
 * 1: contain button
 */

.cn-c-product_cta > footer {
  justify-self: end;
  position: relative;  /* 1 */

  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    grid-column: var(--cn-c-product_cta__footer--GridColumn);
  }
}



/**
 * Button
 * 1: never wrap
 */

.cn-c-product_cta > footer > .cn-c-button {
  display: none;
  inline-size: max-content;  /* 1 */
  inset-block: var(--cn-c-product_cta__button--InsetBlock);
  position: sticky;

  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    display: flex;
  }
}
