/******************************************************************************
 * Common / Atoms / Skip To Main
 *****************************************************************************/

.cn-c-skip_to_main {
  position: fixed;
  top: var(--cn-c-template_shared--top_nav--OffsetHeight);
  left: 0;
  background-color: white;
  margin: .3125rem;
  padding: .5rem;
  transform: translateY(-200%);
  transition: transform var(--cn-global--Duration--sm);
  z-index: 9999;
}

.cn-c-skip_to_main:focus {
  transform: translateY(0%);
}
