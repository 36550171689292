@use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-digital_magazines {
  /**
   * Context label
   */

  --cn-c-digital_magazines__context--Color: var(--cn-global--palette-red);
}

.cn-c-digital_magazines {
  padding-block-start: pxToRem(45px);
  padding-block-end: pxToRem(53px);

  .cn-c-menu_item {
    &::before {
      height: pxToRem(15px);
    }

    > .cn-c-button {
      font: var(--cn-global--Font-label--lg-semibold);
      gap: 0;
      align-items: baseline;

      &::after {
        content: 'DIGITAL';
        font: var(--cn-global--Font-label--xs-regular);
        font-weight: var(--cn-global--FontWeight-medium);
        margin-inline-start: pxToRem(8px);
      }
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
    padding-block: unset !important;

    .cn-c-menu_item > .cn-c-button::after {
      color: var(--cn-c-digital_magazines__context--Color);
    }
  }
}
