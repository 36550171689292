/** ***************************************************************************
 * Common / Organisms / Modal
 *************************************************************************** */

@use "sass:map";
@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-modal {
  position: fixed;
  top: var(--cn-c-template_shared--top_nav--OffsetHeight);
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  visibility: hidden;

  &.is-open {
    pointer-events: auto;
    touch-action: auto;
    visibility: visible;
    opacity: 1;
    transition: visibility, opacity .5s linear;
    z-index: 9999999;
    // overflow: auto;
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--cn-global--palette-dark-gray);
    // opacity: .97;

    @media all and (min-width: 64rem) {
      cursor: none;
    }
  }

  .modal-main {
    // background-color: var(--cn-global--palette-dark-gray);
    height: 100%;
    overflow: auto;
    position: relative;

    .modal-main-container {
      display: grid;
      grid-template-columns: var(--cn-c-page--Grid-Template);
      grid-template-rows: 100%;
      grid-gap: 0 var(--cn-c-page--Grid-Gap);
      flex-direction: column;
      justify-items: center;
      // gap: pxToRem(20px);
      // padding: pxToRem(20px);
      height: 100%;
      max-height: 100vh;  /* eyeballed */
      position: relative;

      &.has-caption {
        grid-template-rows: 86% 14%;

        @media all and (orientation: landscape) and (max-width: map.get($grid-breakpoints, lg)) {
          // height: unset;
          // max-height: unset;
        }

        @media all and (min-width: map.get($grid-breakpoints, xl)) {
          grid-template-rows: 100%;
        }

        @media (pointer:coarse) {
          grid-template-rows: 14% 72% 14%;

          @media all and (min-width: map.get($grid-breakpoints, xl)) {
            grid-template-rows: 14% 86%;
          }
        }
      }

      @media all and (min-width: map.get($grid-breakpoints, xl)) {
        &.is-image {
          // height: unset;
        }
      }

      @media (pointer:coarse) {
        grid-template-rows: 14% 86%;
      }
    }

    .close-wrapper {
      grid-column: -1 / 1;

      flex-basis: 14%;
      align-content: center;

      @media (pointer:coarse) {
        opacity: 1;
        position: unset !important;
        pointer-events: auto;
        margin-block-start: pxToRem(15px);
      }
    }

    .content-wrapper {
      @include utopia.generateClamps((
        "minWidth": 1440,
        "maxWidth": 1920,
        "pairs": (
          (15, 62),
          (45, 62),
          (295, 393),
        ),
        "prefix": "fluid-",
      ));
      grid-column: -1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: fit-content;

      padding-block: pxToRem(20px);
      padding-inline: pxToRem(20px);;

      > * {
        pointer-events: auto;
      }

      > img {
        max-width: 100%;
        max-height: 100%;
      }

      > iframe {
        aspect-ratio: 16 / 9;
        max-height: pxToRem(827px);
        height: auto;
        width: 100%;

        @media all and  (orientation: landscape) {
          // height: 100%;
          // width: auto;
        }
      }

      @media screen and (min-width: map.get($grid-breakpoints, xl)) {
        padding-block: var(--fluid-45-62);
        padding-inline: var(--fluid-295-393);
      }
    }

    .cn-c-video {
      max-width: pxToRem(1380px);
      max-height: 100%;
      width: 100%;
      height: auto;

      .video-js {
        max-width: unset !important;
        width: 100%;
      }

      .cn-c-button-play {
        display: none !important;
      }
    }

    @media all and (min-width: map.get($grid-breakpoints, lg)) {
      // padding: pxToRem(20px);
    }

    @media all and not (pointer:coarse) {
      pointer-events: none;
    }
  }

  .caption {
    color: var(--cn-c-page--BackgroundColor);
    text-align: center;
    padding-inline: var(--cn-global--content-grid--offsetsize);
    // padding-block-end: pxToRem(20px);

    > .wrapper {
      grid-column: -1 / 1;
    }

    @media all and (min-width: map.get($grid-breakpoints, xl)) {
      // grid-column: 11 / span 2;
      position: absolute;
      bottom: pxToRem(100px);
      right: 0;
      left: 0;
      // justify-self: self-start;
      text-align: unset;

      > .wrapper {
        grid-column: 11 / span 2;
      }
    }
  }

}
