@use "~/src/styles/utilities.scss" as *;

.cn-c-button--no-frame {
  --cn-c-button--hover--BackgroundColor: transparent;
  border: none;
  border-radius: unset;
  background-color: transparent;
  color: var(
    --cn-c-button--no-frame--Color,
    var(--cn-c-button--BackgroundColor)
  );
  padding-inline: var(--cn-c-button--PaddingInline);
  padding-block: var(--cn-c-button--PaddingBlock);
  position: relative;
}
