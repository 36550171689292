/******************************************************************************
 * Common / Molecules / Video
 *****************************************************************************/

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-video {
  position: relative;
  // border-radius: 10px;
  overflow: hidden;
  max-height: 555px;
  aspect-ratio: 16/9;

  .video-js {
    position: absolute;
    max-width: 1200px !important;
    height: 100% !important;
    aspect-ratio: 16/9 !important;
    padding-top: 0 !important;

    picture.vjs-poster {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
    }

    &.vjs-has-started {
      picture.vjs-poster {
        img {
          display: none;
        }
      }
    }

    .vjs-big-play-button {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .vjs-text-track-display {
      display: none;
    }
  }

  .video-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: var(--cn-global--palette-black--67);

    &.has-poster {
      .cn-c-button-play {
        display: flex;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .cn-c-loader {
      display: none;
    }

    &.is-loading {
      .cn-c-button-play {
        display: none;
      }
      .cn-c-loader {
        display: block;
      }
    }

    &:focus,
    &:hover {
      .cn-c-button-play .cn-c-button {
        --cn-c-button--BorderColor: var(--cn-c-button-play--hover--BackgroundColor);
        background-color: var(--cn-c-button-play--hover--BackgroundColor);
        // transition: all 0s;
      }
    }
  }

  #vimeo-video {
    display: none;
    max-width: 100%;
    max-height: 34.063rem;
    width: 100%;
    height: 100%;
    position: absolute;

    /** Small */
    @media all and (min-width: 67.5rem) {
      max-height: 46.75rem;
      grid-column: 1/-1;
    }

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .play-load-wrapper {
    position: absolute;
    bottom: pxToRem(40px);
    left: pxToRem(30px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      bottom: pxToRem(80px);
      left: pxToRem(60px);
    }
  }
}
