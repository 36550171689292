/******************************************************************************
 * Common / Atoms / Button / Base
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-button {
  --cn-c-button--ColumnGap: 0.38em; /* 5px */
  --cn-c-button--PaddingBlock: 0.943em; /* 12px */
  --cn-c-button--PaddingInline: 1.615em; /* 21px */
  --cn-c-button--TransitionDuration: var(--cn-global--Duration--xs);
  --cn-c-button--Color: var(--cn-c-page--BackgroundColor);
  --cn-c-button--BackgroundColor: var(--cn-c-page--AccentColor);
  --cn-c-button--BorderColor: var(--cn-c-button--BackgroundColor);
  --cn-c-button--hover--BorderColor: var(--cn-c-button--hover--BackgroundColor);

  --cn-c-button--hover--BackgroundColor: var(
    --cn-c-page--AccentColor--with-opacity
  );
  --cn-c-button--hover--Color: var(--cn-c-button--Color);
}

/**
* Component
* 1: pill
*/

.cn-c-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: var(--cn-c-button--ColumnGap);
  padding-block: var(--cn-c-button--PaddingBlock);
  padding-inline: var(--cn-c-button--PaddingInline);

  background-color: var(--cn-c-button--BackgroundColor);
  color: var(--cn-c-button--Color);
  border-radius: 100vmax; /* 1 */
  border: 1px solid var(--cn-c-button--BorderColor);
  transition: all var(--cn-c-button--TransitionDuration)
    linear;

  cursor: pointer;

  &:hover {
    border-color: var(--cn-c-button--hover--BorderColor);
    color: var(--cn-c-button--hover--Color);
    background-color: var(--cn-c-button--hover--BackgroundColor);
    transition: all var(--cn-c-button--TransitionDuration)
      linear;
  }

  &[disabled] {
    pointer-events: none;
  }

  // &:focus {
  //   outline: none;
  // }
}

/**
* Icon
*/

.cn-c-button svg {
  block-size: 1.125rem;

  /* &:hover {
    background-color: var(--cn-c-button--hover--BackgroundColor);
  } */
}

.cn-c-button.black {
  --cn-c-button--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-button--hover--BackgroundColor: var(--cn-c-page--AccentColor);
  font: var(--cn-global--Font-label--sm-semibold);
  width: fit-content;
}
