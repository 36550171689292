/** ***************************************************************************
 * Common / Organisms / Profile / Login
 *************************************************************************** */

 .cn-c-account_overlay.cn-m-login {
  .cn-c-field {

    input[type=checkbox] {
      border-color: var(--cn-global--palette-dark-blue--31);

      &::before {
        background-color: var(--cn-global--palette-dark-blue);
        opacity: .6;
      }
    }
  }

  .login-options {
    display: flex;
    justify-content: space-between;
    padding-block: 1.25rem 1.875rem;

    .cn-c-field {
      margin: initial;
    }

    a {
      font: var(--cn-global--Font-label--sm-medium);
      text-decoration: underline;
    }
  }

  .personal-info-check {
    font-size: 1rem;
    text-align: center;
  }

  .edit-cta {
    a {
      font: var(--cn-global--Font-paragraph--md-bold);
      color: var(--cn-global--palette-light-blue);
    }
  }

}
