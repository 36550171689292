/******************************************************************************
 * Common / Molecules / Product Card
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-product_card {
  --cn-c-product_card__image--BorderRadius: .25rem;  // pxToRem doesn't work here
  --cn-c-product_card__title--Font: var(--cn-global--Font-title--sm-semibold);

  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    transform: rotate(0deg); // hack to make cta button ::before element to position relative to its grandparent

    border-radius: pxToRem(4px);
    background-color: var(--cn-c-page--BackgroundColor);

    &:hover {
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: var(--cn-c-page--AccentColor);
        border-radius:  0 0 pxToRem(4px) pxToRem(4px);
      }

      .cn-c-button.cta {
        opacity: 1;
      }
    }
  }

  .cn-c-button.cta {
    position: absolute;
    right: 14px;
    top: 14px;
    border: none;
    font-size: pxToRem(13px);
    font-weight: var(--cn-global--FontWeight-semibold);
    --cn-c-button--hover--BackgroundColor: var(--cn-c-page--AccentColor);
    opacity: 0.001;

    &:focus-visible {
      opacity: 1;
    }

    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  img {
    width: 100%;
    border-radius: var(--cn-c-product_card__image--BorderRadius);
  }

  .product_infos {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-inline: pxToRem(30px);
    padding-block: pxToRem(19px) pxToRem(15px);
    height: 100%;
    overflow: hidden;

    :is(h1, h2, h3, h4, h5, h6) {
      font: var(--cn-c-product_card__title--Font);
      line-height: normal;
      margin-block-end: pxToRem(3px);
    }

    .subtitle {
      font-size: pxToRem(15px);
      font-weight: var(--cn-global--FontWeight-regular);
      line-height: normal;
      margin-block: 0 pxToRem(7px);
    }
  }

  .cn-c-variant {
    margin-block-start: pxToRem(12px);

    .options {
      border-block: none;
      gap: pxToRem(7px);
      padding-block: 0;

      .cn-c-button {
        --cn-c-button--PaddingInline: #{pxToRem(10px)};
        font-size: pxToRem(11px);
        letter-spacing: 0;
        height: pxToRem(17px);
      }
    }
  }
}

_::-webkit-full-page-media, _:future, :root .cn-c-product_card .cn-c-button.cta:focus-visible {
  outline: none;
}
