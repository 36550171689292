/** ***************************************************************************
 * Common / Organisms / Profile / Base
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-profile-overlay {
  border-radius: 4px;

  &.cn-m-signup {
    background-color: var(--cn-global--palette-white-smoke);
  }

  .profile-section-title {
    grid-column: 1 / -1;
    margin-block-end: pxToRem(38px);

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      margin-block-end: pxToRem(88px);
    }
  }

  .profile-section-subtitle {
    grid-column: 1 / -1;
    margin-block-end: pxToRem(25px);
  }

  .account-status {
   font: var(--cn-global--FontWeight-regular) pxToRem(11px) var(--cn-global--FontFamily-default);
   margin-block-end: pxToRem(34px);
   letter-spacing: unset;
  }

  .field-title {
    grid-column: span 4;
    font: var(--cn-global--Font-label--md-semibold);
    margin-block-end: .563rem;
  }

  .field-value {
    grid-column: span 8;
    font: var(--cn-global--Font-label--md-regular);
    margin-block-end: 1.063rem;
  }

  .cn-c-button.cn-m-primary,
  .cn-c-field > .input-wrapper,
  .cn-c-field input:not([type="checkbox"]) {
    width: 100%;
  }

  .cn-c-field {
    margin-block: pxToRem(11px);
  }

  .action-buttons {
    margin-block-start: 1.5rem;
    text-align: center;

    &.edit {
      margin-block-start: 1.875rem;
      display: flex;
      align-items: center;
      gap: pxToRem(23px);

      .cn-u-button.cn-c-button {
        display: block;
        margin-block-end: 1.875rem;
      }

      .cancel-cta {
        font: var(--cn-global--Font-label--sm-regular);

        a {
          text-decoration: underline;
        }
      }
    }
  }

  // &.cn-u-user-feedback {
  //   padding-block-end: 3.25rem;

  //   .profile-section-title {
  //     margin-block-end: 1.875rem;
  //   }

  //   > p {
  //     margin-block-end: 0;
  //   }
  // }
}


.cn-c-profile-overlay.cn-m-order_list,
.cn-c-profile-overlay.cn-m-order_detail {
  --cn-c-profile-overlay--m-order_list--FontSize: .75rem;  /* 12px */
  font-size: var(--cn-c-profile-overlay--m-order_list--FontSize);

  @media all and (min-width: map.get($grid-breakpoints, md)) {
    --cn-c-profile-overlay--m-order_list--FontSize: 1rem;  /* 16px */
  }
}

.cn-c-profile-overlay.cn-m-order_list {
  tbody td:last-child > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media all and (min-width: map.get($grid-breakpoints, sm)) {
      flex-direction: row;
    }
  }
}

.cn-c-profile-overlay.cn-m-order_detail{
  section {
    margin-block-start: 2rem;
  }

  .cn-c-cart_summary {
    max-inline-size: 32.5rem;  /* 520px */
    position: static;
  }
}
