/** ***************************************************************************
 * Common / Organisms / Profile / Navigation
 *************************************************************************** */

@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-profile_nav {
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: pxToRem(20px);
    margin-block-start: pxToRem(10px);
    padding-inline-start: unset;
  }

  li {
    position: relative;
    font: var(--cn-global--Font-label--sm-semibold);
    padding-inline-start: pxToRem(25px);
  }

  li::before {
    content: "";
    display: block;
    width: 2px;
    height: 13px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  li.cn-m-current::before {
    background-color: var(--cn-c-page--Color);
  }

  li:hover, li:focus-within {
    a {
      outline: none;
    }

    &::before {
      background-color: var(--cn-c-page--AccentColor);
    }
  }

}
