/** ***************************************************************************
 * Digital / Organisms / Figure
 *************************************************************************** */

 @use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-figure {
  /**
   * Picture
   */

  --cn-c-figure__picture--AspectRatio: initial;
  --cn-c-figure__picture--BorderRadius: .75em;

  /**
   * Button
   */

  --cn-c-figure__button--MarginBlockEnd: 1.25em;
  --cn-c-figure__button--MarginInlineEnd: 1.25em;


  /**
   * Caption
   */

  --cn-c-figure__caption--BackgroundColor: var(--cn-global--palette-black);
  --cn-c-figure__caption--BorderBottomLeftRadius: .5em;
  --cn-c-figure__caption--BorderBottomRightRadius: .5em;
  --cn-c-figure__caption--BorderTopLeftRadius: .5em;
  --cn-c-figure__caption--BorderTopRightRadius: .5em;
  --cn-c-figure__caption--Color: var(--cn-global--palette-white);
  --cn-c-figure__caption--MarginBlockStart: .625em;
  --cn-c-figure__caption--PaddingBlock: 2em;
  --cn-c-figure__caption--PaddingInlineEnd: 3.25em;
  --cn-c-figure__caption--PaddingInlineStart: 2.5em;
  --cn-c-figure__caption--TransitionDuration: .3s;

  --cn-c-figure__caption--InlineSize: 100%;
  --cn-c-figure__caption--lg--InlineSize: 18.75em;  /* 300px */

  /**
   * Caption content
   */

  --cn-c-figure__content--TransitionDuration: .3s;

  /**
   * Picture title
   */

  --cn-c-figure__title--FontWeight: var(--cn-global--FontWeight-semibold);


  /** Large: 1024px */
  @media all and (min-width: map.get($grid-breakpoints, lg)) {
    --cn-c-figure__caption--BorderBottomLeftRadius: 1.25em;
    --cn-c-figure__caption--BorderBottomRightRadius: 3.25em;
    --cn-c-figure__caption--BorderTopLeftRadius: 1.25em;
    --cn-c-figure__caption--BorderTopRightRadius: .5em;
    --cn-c-figure__caption--InlineSize: var(--cn-c-figure__caption--lg--InlineSize);
    --cn-c-figure__caption--InsetBlockEnd: 3em;
    --cn-c-figure__caption--InsetInlineEnd: 3em;
  }
}



/**
 * Component
 * 1: reset
 * 2: contain caption
 */

.cn-c-figure {
  margin: 0;  /* 1 */
  display: grid;
  grid-auto-flow: row;
  position: relative;  /* 2 */
}



/**
 * Picture
 */

.cn-c-figure .cn-c-picture {
  --cn-c-picture__image--AspectRatio: var(--cn-c-figure__picture--AspectRatio);
  --cn-c-picture__image--BorderRadius: var(--cn-c-figure__picture--BorderRadius);
  grid-column: 1;
  grid-row: 1;
  place-self: stretch;
  z-index: 0;
}



/**
 * Button
 */

.cn-c-figure label {
  grid-row: 1;
  grid-column: 1;
  place-self: end;
  margin-block-end: var(--cn-c-figure__button--MarginBlockEnd);
  margin-inline-end: var(--cn-c-figure__button--MarginInlineEnd);
  z-index: 1;
}



/**
 * Toggle
 */

.cn-c-figure input {
  display: none;
}



/**
 * Caption
 */

.cn-c-figure figcaption {
  background-color: var(--cn-c-figure__caption--BackgroundColor);
  block-size: 0px;
  border-radius: var(--cn-c-figure__caption--BorderTopLeftRadius) var(--cn-c-figure__caption--BorderTopRightRadius) var(--cn-c-figure__caption--BorderBottomRightRadius) var(--cn-c-figure__caption--BorderBottomLeftRadius);
  color: var(--cn-c-figure__caption--Color);
  inline-size: var(--cn-c-figure__caption--InlineSize);
  margin-block-start: 0;
  overflow: hidden;
  padding-inline: var(--cn-c-figure__caption--PaddingInlineStart) var(--cn-c-figure__caption--PaddingInlineEnd);
  transition:
    margin var(--cn-c-figure__caption--TransitionDuration) linear,
    block-size var(--cn-c-figure__caption--TransitionDuration) ease;

  &::before,
  &::after {
    block-size: var(--cn-c-figure__caption--PaddingBlock);
    content: "";
    display: block;
  }

  @media all and (min-width: map.get($grid-breakpoints, lg)) {
    inset-block-end: var(--cn-c-figure__caption--InsetBlockEnd);
    inset-inline-end: var(--cn-c-figure__caption--InsetInlineEnd);
    position: absolute;
  }
}

.cn-c-figure input:checked + figcaption {
  block-size: var(--cn-c-figure__caption--BlockSize, auto);
  margin-block-start: var(--cn-c-figure__caption--MarginBlockStart);
}



/**
 * Caption content
 */

.cn-c-figure figcaption > * {
  opacity: 0;
  transition: opacity var(--cn-c-figure__content--TransitionDuration) linear var(--cn-c-figure__caption--TransitionDuration);
}

.cn-c-figure input:checked + figcaption > * {
  opacity: 1;
}



/**
 * Picture title
 */

.cn-c-figure__title {
  font-weight: var(--cn-c-figure__title--FontWeight);
}



/**
 * Caption paragraph
 * 1: reset
 */

.cn-c-figure p {
  font-size: inherit;  /* 1 */
  margin: 0;  /* 1 */
}
