/** ***********************************************************************************
 * Common / Organisms / CMS plugins / Call to action
 *********************************************************************************** */

 @use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-cta {
  --cn-c-cta--PaddingBlockEnd: 2.17em;
  --cn-c-cta--TemplateColumns: auto;

  --cn-c-cta--md__content--ColumnStart: 2;

  /**
   * Image
   */

  --cn-c-cta__image--MarginBlockEnd: 1.33em;

  --cn-c-cta__image--BlockSize: 3.05em;  /* 55px@18 */
  --cn-c-cta__image--sm--BlockSize: 2.92em;  /* 70px@24 */

  /**
   * Content
   */

  --cn-c-cta__content--MarginBlockStart: 0.58em;

  /**
   * Button
   */

  --cn-c-cta__button--MarginBlockStart: 1.33em;
  --cn-c-cta__button--InsetBlock: auto 0;
  --cn-c-cta__button--InsetInline: 0;


  /**
   * Border
   */

  --cn-c-cta__border--BackgroundColor: var(--cn-global--palette-black);
  --cn-c-cta__border--Display: block;


  /** Small: 600px */
  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    --cn-c-cta__image--BlockSize: var(--cn-c-cta__image--sm--BlockSize);
  }

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    --cn-c-cta--TemplateColumns: repeat(7, 1fr);
    --cn-c-cta__button--InsetBlock: 0;
    --cn-c-cta__button--InsetInline: 0 auto;
    --cn-c-cta--PaddingBlockEnd: 0;
  }
}



/**
 * Component
 * 1: contain border
 */

.cn-c-cta {
  align-items: end;
  display: grid;
  grid-column-gap: var(--cn-global--content-grid--guttersize);
  grid-template-columns: var(--cn-c-cta--TemplateColumns);
  grid-template-rows: repeat(3, auto) 1fr;
  justify-items: start;
  padding-block-end: var(--cn-c-cta--PaddingBlockEnd);
  position: relative;  /* 1 */
}



/**
 * Icon
 */

.cn-c-cta > img {
  block-size: var(--cn-c-cta__image--BlockSize);
  grid-area: 1 / 1 / 1 / 1;
  margin-block-end: var(--cn-c-cta__image--MarginBlockEnd);

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    grid-area: 1 / var(--cn-c-cta--md__content--ColumnStart) / 1 / -1;
  }
}



/**
 * Label
 */

.cn-c-cta > .cn-c-button--outline {
  grid-area: 2 / 1 / 2 / 1;
  pointer-events: none;

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    grid-area: 2 / var(--cn-c-cta--md__content--ColumnStart) / 2 / -1;
  }
}



/**
 * Content
 */

.cn-c-cta > p {
  font-size: inherit;
  grid-area: 3 / 1 / 3 / 1;
  margin-block: var(--cn-c-cta__content--MarginBlockStart) 0;

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    grid-area: 3 / var(--cn-c-cta--md__content--ColumnStart) / 3 / -1;
  }
}



/**
 * Button
 */

.cn-c-cta > .cn-c-button.black {
  grid-area: 4 / 1 / 4 / 1;
  margin-block-start: var(--cn-c-cta__button--MarginBlockStart);

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    grid-area: 4 / var(--cn-c-cta--md__content--ColumnStart) / 4 / -1;
  }
}



/**
 * Border
 */

.cn-c-cta::after {
  background-color: var(--cn-c-cta__border--BackgroundColor);
  block-size: 1px;
  border-radius: 100vmax;
  content: "";
  display: var(--cn-c-cta__border--Display);
  inset-block: var(--cn-c-cta__button--InsetBlock);
  inset-inline: var(--cn-c-cta__button--InsetInline);
  position: absolute;

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    block-size: auto;
    inline-size: 1px;
  }
}
