/** ***********************************************************************************
 * Atoms / Typography / Inline elements / Anchor
 *********************************************************************************** */

body {
  /**
   * Link
   */

  --cn-c-page__link--FontWeight: var(--cn-global--FontWeight-bold);
}



/**
 * Component
 */

a {
  color: currentcolor;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* p a {
  font-weight: var(--cn-c-page__link--FontWeight);
} */
