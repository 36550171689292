/** ***************************************************************************
 * Common / Organisms / Product List
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-product_list {
  display: grid;
  grid-template-columns: var(--cn-c-page--Grid-Template);
  grid-gap: 0 var(--cn-c-page--Grid-Gap);
  justify-items: center;
  row-gap: pxToRem(57px);

  &:has(.no-results-label) {
    row-gap: pxToRem(7px);
    justify-items: unset;
  }

  .no-results-label {
    grid-column: 1 / -1;
    font: var(--cn-global--FontWeight-regular) pxToRem(24px) var(--cn-global--FontFamily-default);

    &.title {
      font-weight: var(--cn-global--FontWeight-semibold);
    }
  }

  .cn-c-product_card {
    grid-column: span 2;
    max-width: pxToRem(296px);

    @media screen and (min-width: map.get($grid-breakpoints, sm)) {
      grid-column: span 1;
      max-width: unset;
    }

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      grid-column: span 6;
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      grid-column: span 4;
    }

    @media screen and (min-width: map.get($grid-breakpoints, xl)) {
      grid-column: span 3;
    }
  }
}
