/******************************************************************************
 * Common / Molecules / Price Wrapper
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-price_wrapper {
  display: flex;
  align-items: flex-end;
  gap: pxToRem(2px);

  .inner-price-wrapper {
    display: flex;
    gap: pxToRem(9px);
  }

  .period {
    font-size: pxToRem(17px);
    line-height: pxToRem(23px);
  }

  .cn-c-price {
    position: relative;
    white-space: nowrap;

    &:nth-child(1):not(:only-child) {
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--cn-c-page--AccentColor);
        margin-block-end: pxToRem(12px);
        position: absolute;
        top: 47%;
      }
    }
  }
}
