/******************************************************************************
 * Common / Atoms / Button / Play
 *****************************************************************************/

@use '~/src/styles/utilities.scss' as *;

.cn-c-button-play {
  --cn-c-button-play--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-button-play--hover--BackgroundColor: var(--cn-c-page--AccentColor);
  --cn-c-button-play--Color: var(--cn-c-page--BackgroundColor);
  --cn-c-button-play--FontSize: .5rem; /* 8px */
  --cn-c-button-play--Padding: 9px 19px;  /* 24px */
  --cn-c-button-play--BorderRadius: 25px;  /* 23px */
}


/**
 * Component
 */

.cn-c-button-play {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: pxToRem(11px);
  padding: 0;
  border: 0;

  .cn-c-button--icon-only {
    --cn-c-button--icon-only--Dimension: 49px;

    svg {
      overflow: visible;
      block-size: pxToRem(23px);
    }
  }

  .cn-c-button {
    --cn-c-button--BorderColor: var(--cn-c-button-play--BackgroundColor);
    --cn-c-button--BackgroundColor: var(--cn-c-button-play--BackgroundColor);
    --cn-c-button--hover--BackgroundColor: var(--cn-c-button-play--hover--BackgroundColor);
    border-radius: var(--cn-c-button-play--BorderRadius);
  }

}
