/** ***********************************************************************************
 * Atoms / Typography / Paragraph
 *********************************************************************************** */

p {
  font: var(--cn-global--Font-paragraph--md-regular);
  line-height: 1.222;
  // TOFIX: alternative to split_paragraph?
  // white-space: pre-line;
}
