/** ***************************************************************************
 * Common / Organisms / Profile / Detail
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-profile-overlay.cn-m-detail {

  .fields {
    background-color: var(--cn-global--palette-white-smoke);
    border-radius: 4px;
    padding-block: pxToRem(23px);
    padding-inline: pxToRem(17px);

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      padding-block: pxToRem(53px);
      padding-inline: pxToRem(67px);
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: pxToRem(15px);

    > div:first-child {
      display: flex;
      gap: pxToRem(15px);
      flex-direction: column;

      @media screen and (min-width: map.get($grid-breakpoints, md)) {
        flex-direction: row;
        align-items: center;
      }
    }

    .cn-u-button.cn-c-button:first-of-type {
      margin-block-end: 1.063rem;
    }
    .cn-m-outline--light-blue.shrink {
      width: initial;
      margin-block-start: 1.563rem;
    }

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      flex-direction: row;
      align-items: center;
    }
  }
}

