/******************************************************************************
 * Common / Atoms / Button / Play
 *****************************************************************************/

@use 'utopia-core-scss/src/utopia' as utopia;
@use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-button--close {
  --cn-c-button--icon-only--Dimension: #{pxToRem(45px)};
  --cn-c-button--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-button--hover--BackgroundColor: transparent;
  --cn-c-button--BorderColor: var(--cn-c-page--BackgroundColor);
}


/**
 * Component
 */

.cn-c-button--close {
  // pointer-events: none;
  // touch-action: none;

  > svg {
    block-size: pxToRem(12px);
  }

  &:hover {
    border-width: 2px;
  }

  @media all and (min-width: map.get($grid-breakpoints, xl)) {
    --cn-c-button--icon-only--Dimension: #{pxToRem(85px)};

    > svg {
      block-size: pxToRem(15px);
    }
  }
}
