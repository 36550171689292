/** ***************************************************************************
 * Common / Organisms / Profile / Contact us
 *************************************************************************** */

.cn-c-profile-overlay.cn-m-contact_us {
  padding-block-end: 1.625rem;

  .description {
    font-size: 1.188rem;
    color: var(--cn-global--palette-dark-blue);
    text-align: center;
    padding-block-end: 2.875rem;
  }

  .field-wrapper {
    margin: 0 auto;
    text-align: center;
    max-width: 16rem;

    &:last-of-type {
      border-block-end: 1px solid var(--cn-global--palette-dusty-blue);
    }
  }

  .form-fields {
    margin-block: 1.125rem;
  }

  .action-buttons {
    .cn-m-outline--light-blue.shrink {
      width: initial;
      margin-block-start: 1.563rem;
    }
  }
}

