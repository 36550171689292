/** ***************************************************************************
 * Common / Organisms / Profile / Edit Password
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-profile-overlay.cn-m-edit-password {
  padding-block-end: 2.25rem;

  .fields {
    display: flex;
    flex-wrap: wrap;
    row-gap: pxToRem(8px);
    column-gap: pxToRem(12px);
  }

  .cn-c-field {
    margin-block: unset;
  }

  .cn-c-field:nth-child(1), .action-buttons, .password-criteria {
    width: 100%;
  }

  .password-criteria {
    margin-block: pxToRem(20px) pxToRem(10px);

    .cn-c-label {
      line-height: pxToRem(22px);
    }
  }

  .cn-c-field:nth-child(2), .cn-c-field:nth-child(3) {
    width: 100%;

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      flex: 1;
    }
  }
}
