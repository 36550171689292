/** ***************************************************************************
 * Common / Organisms / Video Section
 *************************************************************************** */

 @use "sass:map";
@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-video_section {
  --cn-c-video_section__video--GridColumn: 1 / -1;
  --cn-c-video_section__video--md--GridColumn: 2 / -2;


  @media screen and (min-width: map.get($grid-breakpoints, md)) {
    --cn-c-video_section__video--GridColumn: var(--cn-c-video_section__video--md--GridColumn)
  }
}


.cn-c-video_section {

  .cn-c-video {
    grid-column: var(--cn-c-video_section__video--GridColumn);
    border-radius: 4px;
    max-height: unset;

    video-js {
      width: 100%;
    }
  }
}
