/** ***********************************************************************************
 * Digital / Organisms / CMS Plugins / Section
 *********************************************************************************** */

@use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-section {
  /**
   * Content
   */

  --cn-c-section__content--GridColumn: 1 / -1;

  /**
   * Header
   */

  --cn-c-section__header--ColumnGap: 1em;  /* eyeballed */
  --cn-c-section__header--RowGap: 1.5rem;
  --cn-c-section__header--GridColumn: var(--cn-c-section__content--GridColumn);

  /**
   * Title
   */

  --cn-c-section__title--FontSize: 1.08em;
  --cn-c-section__title--FontWeight: var(--cn-global--FontWeight-semibold);
  --cn-c-section__title--GridColumn: var(--cn-c-section__content--GridColumn);
  --cn-c-section__title--MarginBlockEnd: .39em;

  /**
   * Bookmark button
   */

  --cn-c-section__bookmark--dimension: 2.2em;
  --cn-c-section__bookmark--Padding: 0.65em;

  --cn-c-section__bookmark--m-active--BackgroundColor: var(--cn-c-page--AccentColor);
  --cn-c-section__bookmark--m-active--Color: var(--cn-c-page--BackgroundColor);

  /**
   * Article descriptor
   */

  --cn-c-section__descriptor--FontSize: max(0.875rem, 0.9em);
  --cn-c-section__descriptor--GridColumn: 2 / span 2;
  --cn-c-section__descriptor--InsetBlock: 9.5rem 0;
  --cn-c-section__descriptor--MarginBlockend: 0;
  --cn-c-section__descriptor--MarginInlineEnd: calc(-1 * var(--cn-global--content-grid--guttersize));

  /**
   * Content
   */

  --cn-c-section__first_paragraph--MarginBlockStart: 0;
  --cn-c-section__first_section--MarginBlockStart: 0;

  /**
   * Wall button plugin
   */

  --cn-c-section__button--MarginBlock: 2.5625em;

  /**
   * Routes plugin
   */

  --cn-c-section__routes--GridColumn: var(--cn-c-section__content--GridColumn);

  /**
   * Picture-like plugins
   */

  --cn-c-section__picture--FontSize: 1rem;
  --cn-c-section__picture--m-horizontal--GridColumn: var(--cn-c-section__content--GridColumn);
  --cn-c-section__picture--m-vertical--GridColumn: var(--cn-c-section__content--GridColumn);

  /**
   * Slider plugin
   */

  --cn-c-section__slider--GridColumn: 1 / -1;


  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    --cn-c-section__content--GridColumn: 5 / -2;
    --cn-c-section__header--GridColumn: 5 / -1;
    --cn-c-section__picture--m-horizontal--GridColumn: 2 / -2;
    --cn-c-section__picture--m-vertical--GridColumn: 5 / -5;
    --cn-c-section__routes--GridColumn: 4 / -2;
    --cn-c-section__slider--GridColumn: 3 / -1;
    --cn-c-section__title--GridColumn: 1 / -2;
  }
}



/**
 * Component
 * 1: contain article descriptor
 */

.cn-c-section {
  position: relative;  /* 1 */
}

.cn-c-section .cn-c-section {
  grid-column: 1 / -1;
}



/**
 * Header
 */

.cn-c-section > header {
  align-items: baseline;
  column-gap: var(--cn-c-section__header--ColumnGap);
  display: flex;
  flex-direction: column;
  grid-column: var(--cn-c-section__header--GridColumn);
  justify-content: space-between;
  row-gap: var(--cn-c-section__header--RowGap);

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: inherit;
  }
}



/**
 * Title
 * 1: reset
 */

.cn-c-section > header > :first-child {
  font-size: var(--cn-c-section__title--FontSize);
  font-weight: var(--cn-c-section__title--FontWeight);
  grid-column: var(--cn-c-section__title--GridColumn);
  margin-block: 0 var(--cn-c-section__title--MarginBlockEnd);
}



/**
 * Bookmark button
 */

.cn-c-section > header > form {
  order: -1;

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    order: initial;
  }
}

.cn-c-section > header .cn-c-button {
  --cn-c-button--BackgroundColor: transparent;
  --cn-c-button--BorderColor: transparent;
  --cn-c-button--Color: currentcolor;
  --cn-c-button--hover--BackgroundColor: transparent;
  --cn-c-button--hover--BorderColor: currentcolor;
  --cn-c-button--hover--Color: currentcolor;
  --cn-c-button--icon-only--Dimension: var(--cn-c-section__bookmark--dimension);
  --cn-c-button--icon-only--Padding: var(--cn-c-section__bookmark--Padding);
  font-size: inherit;

  &.cn-m-active {
    --cn-c-button--BackgroundColor: var(--cn-c-section__bookmark--m-active--BackgroundColor);
    --cn-c-button--BorderColor: var(--cn-c-section__bookmark--m-active--BackgroundColor);
    --cn-c-button--Color: var(--cn-c-section__bookmark--m-active--Color);
  }

  @media all and (pointer: coarse) {
    --cn-c-button--BorderColor: currentcolor;
  }
}



/**
 * Article descriptor
 */

.cn-c-section > .cn-c-article_descriptor {
  align-self: start;
  block-size: 0;  /* 1 */
  display: none;
  font-size: var(--cn-c-section__descriptor--FontSize);
  grid-column: var(--cn-c-section__descriptor--GridColumn);
  grid-row: 1;
  inset-block: var(--cn-c-section__descriptor--InsetBlock);
  margin-block-end: var(--cn-c-section__descriptor--MarginBlockend);
  margin-inline-end: var(--cn-c-section__descriptor--MarginInlineEnd);
  position: sticky;
  z-index: 0;

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    display: block;
  }
}



/**
 * Content
 */

.cn-c-section > * {
  grid-column: var(--cn-c-section__content--GridColumn);
}

.cn-c-section > header + :is(p, .cn-m-lead):nth-child(2) {
  margin-block-start: var(--cn-c-section__first_paragraph--MarginBlockStart);
}

.cn-c-section > header + .cn-c-section:nth-child(2) {
  margin-block-start: var(--cn-c-section__first_section--MarginBlockStart);
}



/**
 * Wall button plugin
 */

.cn-c-section > .cn-c-modal_plugin {
  margin-block: var(--cn-c-section__button--MarginBlock);
}

.cn-c-section > .cn-c-modal_plugin + .cn-c-modal + * {
  margin-block-start: 0;
}



/**
 * Routes plugin
 */

.cn-c-section > .cn-c-routes {
  grid-column: var(--cn-c-section__routes--GridColumn);
}



/**
 * Picture-like plugins
 */

.cn-c-section > :is(.cn-c-picture, .cn-c-figure, .cn-c-picture_pair) {
  font-size: var(--cn-c-section__picture--FontSize);
}

.cn-c-section > :is(.cn-c-picture, .cn-c-figure).cn-m-horizontal {
  grid-column: var(--cn-c-section__picture--m-horizontal--GridColumn);
}

.cn-c-section > :is(.cn-c-picture, .cn-c-figure).cn-m-vertical {
  grid-column: var(--cn-c-section__picture--m-vertical--GridColumn);
}



/**
 * Slider plugin
 */

.cn-c-section > .cn-c-cms_slider {
  grid-column: var(--cn-c-section__slider--GridColumn);
}



/**
 * Picture pair plugin
 * 1: make space for route counter
 * 2: extend shadow to the right
 */

.cn-c-section > .cn-c-picture_pair {
  --cn-c-picture_pair__shadow--GridColumn: 1 / span 3;  /* 1 */
  --cn-c-picture_pair__shadow--size: var(--cn-c-page__picture--MarginBlock);
  grid-column: 1 / -1;
  margin-block-end: 0;
}

.cn-c-section > .cn-c-picture_pair::before {
  margin-inline-end: calc(-2 * var(--cn-global--content-grid--guttersize));  /* 2 */
}



/**
 * Video plugin
 * 1: reset
 */

.cn-c-section > .cn-c-video_section {
  --cn-c-video_section__video--GridColumn: var(--cn-c-section__content--GridColumn);  /* 1 */
  --cn-c-video_section__video--md--GridColumn: var(--cn-c-section__content--GridColumn);  /* 1 */
}
