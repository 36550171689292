/** ***********************************************************************************
 * Common / Organisms / CMS plugins / Picture call to action
 *********************************************************************************** */

 @use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-cta_picture {
  --cn-c-cta_picture--BorderRadius: 1.25em 1.25em 0 0;
  --cn-c-cta_picture--PaddingBlock: 0;
  --cn-c-cta_picture--PaddingInlineStart: 0;
  --cn-c-cta_picture--TransitionDuration: 0.15s;

  /**
   * Border
   */

  --cn-c-cta_picture__border--BackgroundColor: currentcolor;

  /**
   * Image
   */

  --cn-c-cta_picture__image--AspectRatio: 375 / 250;
  --cn-c-cta_picture__image--BorderRadius: 1.25em;
  --cn-c-cta_picture__image--TransitionDuration: var(--cn-c-cta_picture--TransitionDuration);

  /**
   * CTA
   */

  --cn-c-cta_picture__cta--BackgroundColor: var(--cn-global--palette-white);
  --cn-c-cta_picture__cta--BorderRadius: 0;
  --cn-c-cta_picture__cta--ImageMarginBlockEnd: 1em;
  --cn-c-cta_picture__cta--InlineSize: 100%;
  --cn-c-cta_picture__cta--PaddingBlock: 1.39em 2.17em;
  --cn-c-cta_picture__cta--PaddingInline: 2.21em 1.125em;
  --cn-c-cta_picture__cta--TransitionDuration: var(--cn-c-cta_picture--TransitionDuration);


  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    --cn-c-cta_picture--BorderRadius: 0.17em;
    --cn-c-cta_picture--PaddingBlock: 6.08em;
    --cn-c-cta_picture--PaddingInlineStart: 3.42em;
    --cn-c-cta_picture__cta--BorderRadius: 1.21em;
    --cn-c-cta_picture__cta--InlineSize: 23.44rem;
    --cn-c-cta_picture__cta--PaddingBlock: 1.46em 2.75em;
    --cn-c-cta_picture__image--AspectRatio: initial;
    --cn-c-cta_picture__image--BorderRadius: 0;
  }
}



/**
 * Component
 * 1: contain image
 */

.cn-c-cta_picture {
  border-radius: var(--cn-c-cta_picture--BorderRadius);
  overflow: hidden;
  padding-block: var(--cn-c-cta_picture--PaddingBlock);
  padding-inline-start: var(--cn-c-cta_picture--PaddingInlineStart);
  position: relative;  /* 1 */
  transition: border-radius var(--cn-c-cta_picture--TransitionDuration) linear;
}


/**
 * Border
 * 1: pill
 */

.cn-c-cta_picture::after {
  background-color: var(--cn-c-cta_picture__border--BackgroundColor);
  block-size: 1px;
  border-radius: 100vmax;  /* 1 */
  content: "";
  display: block;

  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    display: none
  }
}



/**
 * Image
 */

.cn-c-cta_picture > img {
  aspect-ratio: var(--cn-c-cta_picture__image--AspectRatio);
  border-radius: var(--cn-c-cta_picture__image--BorderRadius);
  inline-size: 100%;
  object-fit: cover;
  transition: border-radius var(--cn-c-cta_picture__image--TransitionDuration) linear;
  z-index: -1;

  @media all and (min-width: map.get($grid-breakpoints, sm)) {
    block-size: 100%;
    inset: 0;
    position: absolute;
  }
}



/**
 * CTA
 */

.cn-c-cta_picture > .cn-c-cta {
  --cn-c-cta__border--Display: none;
  --cn-c-cta__image--MarginBlockEnd: var(--cn-c-cta_picture__cta--ImageMarginBlockEnd);
  --cn-c-cta--md__content--ColumnStart: 1;
  background-color: var(--cn-c-cta_picture__cta--BackgroundColor);
  border-radius: var(--cn-c-cta_picture__cta--BorderRadius);
  inline-size: var(--cn-c-cta_picture__cta--InlineSize);
  padding-block: var(--cn-c-cta_picture__cta--PaddingBlock);
  padding-inline: var(--cn-c-cta_picture__cta--PaddingInline);
  transition: border-radius var(--cn-c-cta_picture__cta--TransitionDuration) linear;
}
