/** ***************************************************************************
 * Common / Atoms / Picture
 *************************************************************************** */

@use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-picture {
  --cn-c-picture--BackgroundColor: var(--cn-global--palette-white);;

  /**
   * Shadow
   */

  --cn-c-picture__shadow--Display: none;
  --cn-c-picture__shadow--GridColumn: 1 / span 4;
  --cn-c-picture__shadow--InlineSize: 100%;

  --cn-c-picture__shadow--blur: var(--cn-c-picture__shadow--size);
  --cn-c-picture__shadow--color: var(--cn-global--palette-white);
  --cn-c-picture__shadow--size: 2rem;  /* must be overridden */

  /**
   * Image
   */

  --cn-c-picture__image--BorderRadius: .75em;
  --cn-c-picture__image--AspectRatio: initial;
}



/**
 * Component
 * 1: contain shadow
 */

.cn-c-picture {
  background-color: var(--cn-c-picture--BackgroundColor);
  position: relative;  /* 1 */
}



/**
 * Shadow
 * 1: cut shadow
 * 2: behind image
 * 3: extend opaque part to the left and right
 */

.cn-c-picture::before {
  box-shadow:
    0 var(--cn-c-picture__shadow--size) var(--cn-c-picture__shadow--blur) var(--cn-c-picture__shadow--color),
    0 calc(-1 * var(--cn-c-picture__shadow--size)) var(--cn-c-picture__shadow--blur) var(--cn-c-picture__shadow--color);
  box-sizing: content-box;
  clip-path: inset(-100vmax 0);  /* 1 */
  content: "";
  display: none;
  inline-size: var(--cn-c-picture__shadow--InlineSize);
  inset-block: 0;
  inset-inline: 0 auto;
  margin-inline-start: calc(-1 * var(--cn-global--content-grid--offsetsize));  /* 3 */
  padding-inline-end: var(--cn-global--content-grid--guttersize);  /* 3 */
  padding-inline-start: var(--cn-global--content-grid--offsetsize);  /* 3 */
  position: absolute;
  z-index: 0;  /* 2 */

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    display: var(--cn-c-picture__shadow--Display);
  }

}


/**
 * Image
 */

.cn-c-picture img {
  aspect-ratio: var(--cn-c-picture__image--AspectRatio);
  block-size: auto;
  border-radius: var(--cn-c-picture__image--BorderRadius);
  display: block;
  inline-size: 100%;
  object-fit: cover;
  z-index: 1;
}
