@use "~/src/styles/utilities.scss" as *;

.cn-c-button--no_frame_with_button {
  --cn-c-button--no_frame_with_button--BackgroundColor: var(--cn-c-page--AccentColor);
  --cn-c-button--no_frame_with_button--Color: var(--cn-c-page--BackgroundColor);
}

.cn-c-button--no_frame_with_button {
  > .icon-wrapper {
    block-size: pxToRem(16px);
    border-radius: 100vmax;
    padding: 3px;
    display: flex;
    aspect-ratio: 1;

    background-color: var(--cn-c-button--no_frame_with_button--BackgroundColor);
    color: var(--cn-c-button--no_frame_with_button--Color);
  }

  svg {
    aspect-ratio: 1;
    block-size: unset;
  }

  &:hover {
    background-color: transparent;
  }
}
