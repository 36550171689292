@use "~/src/styles/utilities.scss" as *;

.cn-c-button--icon-only {
  --cn-c-button--icon-only--Dimension: #{pxToRem(29px)};
  --cn-c-button--icon-only--Padding: #{pxToRem(8px)};
  padding-block: var(--cn-c-button--icon-only--PaddingBlock, var(--cn-c-button--icon-only--Padding));
  padding-inline: var(--cn-c-button--icon-only--PaddingInline, var(--cn-c-button--icon-only--Padding));
  width: var(--cn-c-button--icon-only--Dimension);
  height: var(--cn-c-button--icon-only--Dimension);
}
