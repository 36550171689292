/** ***********************************************************************************
 * Common / Organisms / CMS plugins / Double call to action
 *********************************************************************************** */

 @use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-cta_double {
  --cn-c-cta_double--ColumnGap: 2rem;
  --cn-c-cta_double--PaddingBlock: 0;
  --cn-c-cta_double--RowGap: 2rem;

  /**
   * Divider
   */

  --cn-c-cta_double__divider--BackgroundColor: var(--cn-global--palette-black);

  /**
   * CTA
   */

  --cn-c-cta_double__cta--MaxInlineSize: 12.33em;


  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    --cn-c-cta_double--PaddingBlock: 2em;
  }
}



/**
 * Component
 */

.cn-c-cta_double {
  display: grid;
  grid-column-gap: var(--cn-c-cta_double--ColumnGap);
  grid-row-gap: var(--cn-c-cta_double--RowGap);
  justify-items: stretch;
  grid:
    "cta1"
    "divider"
    "cta2"
    "border";

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    grid:
      ".    divider ." var(--cn-c-cta_double--PaddingBlock)
      "cta1 divider cta2"
      ".    divider ." var(--cn-c-cta_double--PaddingBlock)
    / 1fr auto 1fr;
    justify-items: center;
  }
}



/**
 * Divider
 * 1: pill-shaped
 */

.cn-c-cta_double::before,
.cn-c-cta_double::after {
  align-self: center;
  background-color: var(--cn-c-cta_double__divider--BackgroundColor);
  block-size: 1px;
  border-radius: 100vmax;  /* 1 */
  content: "";
  display: block;
}

.cn-c-cta_double::before {
  grid-area: divider;
  margin-block: calc(-1 * var(--cn-c-cta_double--PaddingBlock));

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    block-size: 100%;
    inline-size: 1px;
  }
}

.cn-c-cta_double::after {
  grid-area: "border";

  /** Medium: 768px */
  @media all and (min-width: map.get($grid-breakpoints, md)) {
    display: none
  }
}


/**
 * CTA
 * 1: no border
 */

.cn-c-cta_double > .cn-c-cta {
  --cn-c-cta--PaddingBlockEnd: 0;  /* 1 */
  --cn-c-cta__border--Display: none;  /* 1 */
  --cn-c-cta--md__content--ColumnStart: 1;
  max-inline-size: var(--cn-c-cta_double__cta--MaxInlineSize);

  &:first-child {
    grid-area: cta1;
  }

  &:last-child {
    grid-area: cta2;
  }
}
