/******************************************************************************
 * Icons / UI / Hamburger
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-icon--hamburger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 1rem;
  width: 1rem;
  margin-inline: auto;
  pointer-events: none;

  .bar {
    height: pxToRem(1.92px);
    width: pxToRem(13px);
    display: block;
    margin-block: pxToRem(3px);
    position: relative;
    background-color: currentColor;
    transition: transform var(--cn-global--Duration--sm), opacity var(--cn-global--Duration--sm);
  }

  .is-open & {
    .bar {
      &:nth-of-type(1) {
        transform: translateY(5px) rotate(45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
}
