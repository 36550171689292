/******************************************************************************
 * Common / Atoms / Badge
 *****************************************************************************/

.cn-c-badge {
  --cn-c-badge--BackgroundColor: var(--cn-c-page--AccentColor);
  --cn-c-badge--Color: var(--cn-c-page--BackgroundColor);
  --cn-c-badge--Font: var(--cn-global--Font-label--xxs-medium);
}



/**
 * Component
 */
.cn-c-badge {
  background-color: var(--cn-c-badge--BackgroundColor);
  border-radius: 7px;
  color: var(--cn-c-badge--Color);
  display: block;
  font: var(--cn-c-badge--Font);
  height: 13px;
  min-width: 16px;
  padding-inline: 3px;
  pointer-events: none;
  text-align: center;
  width: fit-content;
}
