@use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-header_heading {
  padding-inline-start: 1.125rem;
  z-index: 7;
  text-transform: uppercase;
  text-rendering: geometricPrecision;

  // @media screen and (min-width: map.get($grid-breakpoints, xl)) {
  //   display: flex;
  //   flex-direction: row;
  //   gap: pxToRem(5px);
  //   padding-inline-start: 0;
  // }
}
