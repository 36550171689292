/** ***************************************************************************
 * Common / Molecules / Notification Card
 *************************************************************************** */

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-notification_card {
  background-color: var(--cn-c-page--Color);
  color: var(--cn-c-page--BackgroundColor);
  padding-block: pxToRem(30px) pxToRem(22px);
  padding-inline: pxToRem(16px);

  display: flex;
  flex-direction: column;
  gap: pxToRem(14px);
  position: relative;
  transition: all var(--cn-global--Duration--sm) ease-out;
  max-height: 100%;

  &.level-error {
    background-color: var(--cn-c-page--AccentColor);
  }

  &.is-hidden {
    transform: translateX(-100%);
    max-height: 0;
    padding: 0;
    opacity: 0;
  }

  .close-button {
    --cn-c-button--no-frame--Color: var(--cn-c-page--BackgroundColor);
    position: absolute;
    right: 7px;
    top: 7px;

    @media all and (min-width: map.get($grid-breakpoints, lg)) {
      right: 7px;
      top: 7px;
    }
  }

  .warning-signal {
    display: flex;
    align-items: center;
    gap: pxToRem(10px);

    svg {
      block-size: pxToRem(20px);
    }
  }

  a:not(.cn-c-button) {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  .message {
    font: var(--cn-global--Font-label--xs-regular);
    letter-spacing: 0.2px;
  }

  .cart-price {
    display: flex;
    align-items: center;
    gap: pxToRem(10px);

    svg {
      block-size: pxToRem(21px);
    }
  }

  .info-actions {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: pxToRem(11px);

    @media all and (min-width: map.get($grid-breakpoints, lg)) {
      flex-direction: row;
      align-items: center;
    }
  }

  .buttons {
    display: flex;
    gap: 0.6875rem;
  }

  .cn-c-button:not(.close-button) {
    --cn-c-button--Color: var(--cn-c-page--Color);
    --cn-c-button--BackgroundColor: var(--cn-c-page--BackgroundColor);
    --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);
    --cn-c-button--hover--BackgroundColor: var(--cn-c-page--AccentColor);
    font: var(--cn-global--FontWeight-semibold) pxToRem(13px) var(--cn-global--FontFamily-default);
  }

  @media all and (min-width: map.get($grid-breakpoints, lg)) {
    padding-block: pxToRem(22px);
    padding-inline: pxToRem(68px) pxToRem(35px);
    width: pxToRem(497px);
    border-radius: 0 4px 4px 0;
  }
}
