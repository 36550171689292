/** ***************************************************************************
 * Common / Organisms / Filters Panel
 *************************************************************************** */

 @use "sass:map";
@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-filters_panel {
  background-color: var(--cn-global--palette-dark-gray--60);
  position: fixed;
  inset: 0;
  z-index: 100;

  opacity: 0;
  pointer-events: none;
  transition: var(--cn-global--Duration--sm);
  visibility: hidden;

  > .container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    background-color: var(--cn-c-page--Color);
    color: var(--cn-c-page--BackgroundColor);
    padding: pxToRem(16px);
    overflow-y: auto;
    max-height: 100vh;

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      position: sticky;
      top: pxToRem(40px);
      background-color: unset;
      color: unset;
      padding: 0;
    }
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-block: pxToRem(5px) pxToRem(21px);
    padding-inline: pxToRem(28px);

    .cn-c-button--no-frame {
      --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);
      --cn-c-button--no-frame--Color: var(--cn-c-page--BackgroundColor);

      .icon-wrapper {
        color: var(--cn-c-page--BackgroundColor);
      }

      &:first-child {
        pointer-events: none;
        padding-block: unset;
      }
    }

    .cn-c-button--no-frame.close-button {
      flex-direction: row;
      font-size: pxToRem(14px);

      .icon-wrapper {
        block-size: pxToRem(13px);
      }
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      display: none;

      + .cn-u-divider {
        display: none;
      }
    }
  }

  .filter-group {
    --filter-group--BorderColor: var(--cn-global--border--DarkTheme);
    padding-block: pxToRem(34px);
    border-block-end: var(--filter-group--BorderColor);

    &:first-child {
      border-block-start: var(--filter-group--BorderColor);
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      --filter-group--BorderColor: var(--cn-global--border)
    }
  }

  fieldset, ul, li {
    margin: 0;
    padding: 0;
    border: unset;
    list-style-type: none;
  }

  .options-wrapper > ul {
    margin-block-start: pxToRem(25px);
  }

  .options-wrapper ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: pxToRem(13px);
  }

  .suboptions-wrapper {
    margin-inline-start: pxToRem(16px);
    margin-block-start: pxToRem(13px);
  }

  label.pill {
    --cn-c-button--outline--Color: var(--cn-c-page--BackgroundColor);
    --cn-c-button--outline--BackgroundColor: var(--cn-c-page--Color);
    --cn-c-button--hover--Color: var(--cn-c-button--outline--BackgroundColor);
    --cn-c-button--hover--BackgroundColor: var(--cn-c-button--outline--Color);
    --cn-c-button--BorderColor: var(--cn-c-button--outline--Color);
    font: var(--cn-global--Font-label--xs-book);

    &:focus-visible {
      outline: 1px solid var(--cn-c-page--Color);
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      --cn-c-button--outline--Color: var(--cn-c-page--Color);
      --cn-c-button--outline--BackgroundColor: transparent;
      --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);
      --cn-c-button--hover--BackgroundColor: var(--cn-c-button--outline--Color);
    }
  }

  .cn-c-field:has(input:checked) + label.pill  {
    --cn-c-button--outline--Color: var(--cn-c-page--Color);
    --cn-c-button--outline--BackgroundColor: var(--cn-c-page--BackgroundColor);
    --cn-c-button--hover--Color: var(--cn-c-button--outline--Color);
    --cn-c-button--hover--BackgroundColor: var(--cn-c-button--outline--BackgroundColor);
    --cn-c-button--BorderColor: var(--cn-c-button--outline--BackgroundColor);

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      --cn-c-button--outline--Color: var(--cn-c-page--BackgroundColor);
      --cn-c-button--outline--BackgroundColor: var(--cn-c-page--Color);
      --cn-c-button--hover--Color: var(--cn-c-button--outline--Color);
      --cn-c-button--hover--BackgroundColor: var(--cn-c-button--outline--BackgroundColor);
      --cn-c-button--BorderColor: var(--cn-c-button--outline--BackgroundColor);
    }
  }

  .cn-c-field:has(input:focus-visible) + label.pill  {
    outline: 1px solid var(--cn-c-page--Color);
  }

  .cn-c-field input[type=checkbox],
  .cn-c-field input[type=radio] {
    border-color: var(--cn-c-page--BackgroundColor);

    &::before {
      background-color: var(--cn-c-page--BackgroundColor);
    }
  }

  .action-button {
    display: flex;
    justify-content: center;
    gap: pxToRem(16px);
    margin-block: pxToRem(15px);

    .cn-c-button {
      --cn-c-button--BackgroundColor: var(--cn-c-page--AccentColor);
      --cn-c-button--hover--Color: var(--cn-c-page--Color);
      --cn-c-button--hover--BackgroundColor: var(--cn-c-page--BackgroundColor);
      font-size: pxToRem(14px);
      font-weight: var(--cn-global--FontWeight-semibold);
    }

    .cn-c-button--outline {
      --cn-c-button--outline--Color: var(--cn-c-page--BackgroundColor);
      --cn-c-button--PaddingInline: 1.615em;
      text-transform: unset;
      letter-spacing: unset;
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      justify-content: flex-start;

      .cn-c-button {
        --cn-c-button--BackgroundColor: var(--cn-c-page--Color);
        --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);
        --cn-c-button--hover--BackgroundColor: var(--cn-c-page--AccentColor);
      }

      .cn-c-button--outline {
        --cn-c-button--outline--Color: var(--cn-c-page--Color);
      }
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, lg)) {
    position: unset;
    background-color: unset;
    z-index: 98;

    .cn-c-field input[type=checkbox],
    .cn-c-field input[type=radio] {
      border-color: var(--cn-c-page--Color);

      &::before {
        background-color: var(--cn-c-page--Color);
      }
    }
  }
}
