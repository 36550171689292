/** ***************************************************************************
 * Common / Organisms / Catalog Toolbar
 *************************************************************************** */

 @use "sass:map";
@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-catalog_toolbar {
  display: flex;
  margin-block-end: pxToRem(34px);
  justify-content: center;

  .filters-trigger-bar {
    position: fixed;
    bottom: pxToRem(14px);
    left: 0;
    right: 0;
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--cn-c-page--Color);
    margin-inline: var(--cn-global--content-grid--offsetsize);
    border-radius: pxToRem(25px);
    height: pxToRem(50px);
    padding-inline: pxToRem(30px);

    opacity: 0;
    pointer-events: none;
    transition: opacity var(--cn-global--Duration--sm) ease-in-out;

    .cn-c-button--no-frame {
      --cn-c-button--no-frame--Color: var(--cn-c-page--BackgroundColor);
      --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);

      .icon-wrapper {
        color: var(--cn-c-page--BackgroundColor);
      }
    }

    .back-to-top {
      flex-direction: row;
      padding-inline-start: pxToRem(13px);
      font-size: pxToRem(14px);

      .icon-wrapper {
        transform: rotate(-90deg);
        block-size: pxToRem(16px);
      }
    }

    &.is-visible {
      opacity: 1;
      pointer-events: auto;
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      position: unset;
      background-color: unset;
      margin-inline: 0;
      padding: 0;
      opacity: 1;
      pointer-events: auto;

      .cn-c-button--no-frame {
        --cn-c-button--no-frame--Color: var(--cn-c-page--Color);
        --cn-c-button--hover--Color: var(--cn-c-page--Color);

        .icon-wrapper {
          color: var(--cn-c-page--Color);
        }
      }

      .back-top-wrapper {
        display: none;
      }
    }
  }

  .filters-trigger {
    padding-inline-end: pxToRem(41px);

    &:hover {
      ~ .hover-icon svg {
        block-size: pxToRem(20px);
        margin-inline-start: calc(-1 * pxToRem(21px));
        vertical-align: text-top;
      }
    }

    &.is-active {
      ~ .hover-icon svg {
        display: none;
      }

      ~ .close-icon svg {
        block-size: pxToRem(14px);
        margin-inline-start: calc(-1 * pxToRem(19px));
      }
    }
  }

  .back-top-wrapper {
    display: flex;
    align-items: center;

    .vertical-separator {
      width: 1px;
      height: pxToRem(18px);
      background-color: var(--cn-global--palette-white);
    }
  }

  .pagination-infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: pxToRem(5px);
    width: 100%;

    .total-count .cn-c-label {
      display: inline-block;

      + .cn-c-label {
        margin-inline-start: pxToRem(9px);
      }
    }

    .vertical-separator {
      display: none;
      width: 1px;
      height: pxToRem(27px);
      background-color: var(--cn-c-page--Color);
    }

    .pages > * {
      display: inline-block;
    }

    .pages .slash-separator {
      margin-inline: pxToRem(6px);
    }

    .cn-c-pagination {
      margin-block-start: unset;

      .step-links {
        .current {
          > :not(.number):not(.total-count):not(.slash-separator):not(.total-count-mobile) {
            display: none;
          }
          > .slash-separator, .total-count-mobile {
            display: block;
          }
        }

        .previous,
        .next {
          &.hidden {
            display: none;
          }
          @media screen and (min-width: map.get($grid-breakpoints, lg)) {
            display: none;
          }
        }

        .total-count {
          pointer-events: none;
        }
      }
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      flex-direction: row;
      gap: pxToRem(30px);
      width: unset;

      .vertical-separator {
        display: block;
      }

      .total-count .cn-c-label {
        + .cn-c-label {
          margin-inline-start: pxToRem(13px);
        }
      }
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, lg)) {
    justify-content: flex-end;

    &:has(.filters-trigger-bar) {
      justify-content: space-between;
    }
  }
}
