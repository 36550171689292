/******************************************************************************
 * Common / Molecules / Main Brand Reference
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-main_brand_ref {
  display: flex;
  align-items: center;
  gap: pxToRem(10px);

  svg {
    block-size: pxToRem(37px);
  }

  .cn-c-header_heading {
    gap: pxToRem(7px);
  }

  .context-label {
    font-weight: var(--cn-global--FontWeight-medium);
  }
}
