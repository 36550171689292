/******************************************************************************
 * Common / Molecules / Socials
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-socials {
  --cn-c-socials--Color: var(--cn-c-page--Color);
  --cn-c-socials--BackgroundColor: var(--cn-c-page--BackgroundColor);
  display: flex;
  gap: pxToRem(22px);

  .cn-c-button {
    --cn-c-button--outline--Color: var(--cn-c-socials--BackgroundColor);
    --cn-c-button--outline--BackgroundColor: var(--cn-c-socials--Color);
    --cn-c-button--BorderColor: var(--cn-c-socials--Color);
    --cn-c-button--hover--Color: var(--cn-c-socials--BackgroundColor);
    --cn-c-button--hover--BackgroundColor: var(--cn-c-page--AccentColor);
    width: pxToRem(22px);
    height: pxToRem(22px);
    padding: pxToRem(4px);

    &.youtube {
      padding: pxToRem(2px);
    }

    &.instagram {
      padding: pxToRem(3px);
    }
  }
}
