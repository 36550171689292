/** ***********************************************************************************
 * Common / Atoms / Typography / Heading
 *********************************************************************************** */

@use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

h1 {
  font: var(--cn-global--Font-title--2xl-semibold);
  line-height: var(--cn-global--LineHeight-xl);
  margin-block-start: 0;
  margin-block-end: pxToRem(53px);

  @media all and (min-width: map.get($grid-breakpoints, xl)) {
    font: var(--cn-global--Font-title--3xl-semibold);
    line-height: var(--cn-global--LineHeight-2xl);
  }
}

h3 {
  font: var(--cn-global--Font-title--lg-semibold);
  line-height: var(--cn-global--LineHeight-md);
  margin-block-start: 0;
  margin-block-end: pxToRem(46px);
}
