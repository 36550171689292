/** ***************************************************************************
 * Common / Atoms / Forms / Widgets / Custom Select
 *************************************************************************** */

@use "~/src/styles/utilities.scss" as *;

.cn-c-custom_select {
  position: relative;
  padding-right: pxToRem(30px); /* Spazio per la freccetta */

  .custom_select-inner {
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      right: pxToRem(10px);
      top: 46%;
      width: pxToRem(10px);
      height: pxToRem(10px);
      border-left: 1px solid #333;
      border-bottom: 1px solid #333;
      transform: translateY(-50%) rotate(-45deg);
      transition: transform var(--cn-global--Duration--xs) ease; /* Animazione */
      // pointer-events: none;
    }

    &[aria-expanded="true"]::after {
      transform: translateY(-4%) rotate(135deg); /* Ruota la chevron quando il dropdown è aperto */
    }
  }

  .selected-option {
    padding: pxToRem(10px);

    &.is-placeholder {
      font: var(--cn-global--Font-label--xs-regular);
      color: var(--cn-c-page--Color);
    }
  }

  .dropdown {
    display: none;
    border-top: 1px solid #ccc;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    z-index: 10000;
  }

  .option {
    padding: pxToRem(10px);
    cursor: pointer;
  }

  .option:hover {
    background-color: #f1f1f1;
  }

  .option-content {
    display: flex;
    flex-direction: column;
  }

  .option-title {
    font-weight: bold;
  }

  .option-description {
    font-size: pxToRem(12px);
    color: #666;
  }
}
